import React, { FunctionComponent } from "react";
import styled from "styled-components";
import useDevice from "hooks/useDevice";
import {
  secondEllipse,
  secondStep,
  mobileSecondEllipse,
  chestGif
} from "helper/constants";

const SecondWrapper = styled.div`
  padding-top: 150px;
  @media (max-width: 900px) {
    padding-top: 62px;
  }
  .portal-detail {
    grid-area: first;
    max-width: 460px;
    @media (max-width: 900px) {
      width: 100%;
      max-width: 100%;
    }
  }
  .portal-container {
    display: grid;
    grid-template-columns: 50% 50%;
    grid-template-areas: "first second";
    padding: 0 20px;

    @media (max-width: 900px) {
      grid-template-columns: 100%;
      gap: 50px;
      grid-template-areas: "second" "first";
    }
    img {
      max-width: 440px;
    }
  }
  @media (max-width: 575px) {
    padding-top: 22px !important;
    .game_title {
      font-size: 28px !important;
    }
    .game_desc {
      margin-top: 20px !important;
      font-size: 20px !important;
    }

    .portal-detail {
      margin-right: 0px !important;
      text-align: center;
    }
    .portal-container {
      img {
        max-width: 90%;
      }
    }
  }
`;

interface SecondProps {}

const Second: FunctionComponent<SecondProps> = () => {
  const { isMobile } = useDevice();

  const preloadGif = new Image();
  preloadGif.src = chestGif;

  return (
    <div className="relative">
      <img
        src={isMobile ? mobileSecondEllipse : secondEllipse}
        alt="secondEllipse"
        className="absolute second_ellipse"
        style={{ right: "0", zIndex: "-1", top: "-50px" }}
      />
      <SecondWrapper className="app-container">
        <div className="portal-container">
          <div className="portal-detail mr40">
            <div className="an-36 b-bold-text game_title">
              2. AQUIS ($AQI) Airdrop
            </div>
            <div className="an-24 vt-regular-text lightgray--text mt30 game_desc">
            {/* Allocate AQUIS to strategically attack other nations to pillage their lands and
            gain more AQUIS during battle (battles occur every 24 hours). */}
            Strap in for the AQUIS airdrop, you degenerate masterminds! Allocate your AQUIS to launch raids on other clans, pillaging their lands for a sweet haul of more AQUIS. It's like farming yield but with more chaos and less farming. Use your AQUIS to unlock the sickest attacks or defenses, climb the leaderboard, and secure your spot as a legend.
            </div>
          </div>
          <div style={{ gridArea: "second" }}>
            <img src={`${chestGif}?${new Date().getTime()}`} alt="secondStep" />
          </div>
        </div>
      </SecondWrapper>
    </div>
  );
};

Second.propTypes = {};

Second.defaultProps = {};

export default Second;
