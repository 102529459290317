import React, { FunctionComponent } from "react";
import styled from "styled-components";
import useDevice from "hooks/useDevice";
import {
  discordIcon,
  openSeaIcon,
  scrollUpBtn,
  townhallLogo,
  twitterIcon,
  mobileScrollUpBtn,
} from "helper/constants";

const FooterWrapper = styled.div`
  background: #393964;
  padding-top: 190px;
  a {
    color: white;
  }
  .width-divide {
    max-width: 15%;
  }
  .icons {
    border-top: 1px solid rgba(255, 255, 255, 0.4);
  }
  @media (max-width: 575px) {
    .mobile_title {
      padding: 0 !important;
      padding-top: 20px !important;
    }
    .about_name {
      padding-top: 0 !important;
    }
    .last_container {
      display: grid;
      justify-items: center;
      justify-content: space-around;
      margin: 12px;
    }
    .grid-row-2-3 {
      grid-row: 2/3;
      margin-bottom: 12px;
    }
    .policy-section {
      width: 100%;
      justify-content: space-around;
    }
    .policy-sub-section {
      padding-left: 0px;
      margin-right: 0px;
    }
    .social_container {
      padding: 20px;
    }
    .nav_container {
      display: block !important;
      justify-self: flex-end;
      grid-row: 1/3;
      grid-column: 2/3;
    }
    .width-divide {
      max-width: 100%;
    }
    .mob_div {
      display: grid;
      grid-template-columns: 1fr 1fr;
      align-items: start;
      padding: 22px 40px 28px 25px;
    }

    .logo_mobile{
      visibility: hidden;
      height: 0px;
    }
  }
`;

interface FooterProps {
  hideLogo?: any;
}

const Footer: FunctionComponent<FooterProps> = ({hideLogo=false}) => {
  const { isMobile } = useDevice();

  return (
    <FooterWrapper>
      {/* <img
        src={townhallLogo}
        alt="townhallLogo"
        className={isMobile ? "logo_mobile ml20" : "flex margin-auto"}
        hidden={hideLogo}
      /> */}
      <div className="footer-detail pb30 app-container ">
        <div className="flex items-center pt22 pb28 app-container justify-between mob_div">
          <div
            className={`${
              isMobile ? "an-20" : "an-28"
            } b-bold-text width-divide float-left`}
          >
          <img hidden={!hideLogo} src={townhallLogo} alt="townhallLogo" className="owner-logo cursor-pointer"/>
          </div>
          <div
            className={`${
              isMobile ? "an-20" : "an-24"
            } flex nav_container vt-regular-text`}
          >
            <a href="#about">
              <div className=" mobile_title pl55 about_name">About</div>
            </a>
            <a href="#survivaljourney">
              <div className=" mobile_title px40">Survival Journey</div>
            </a>
            <a href="#gamestep">
              <div className=" mobile_title">Game Steps</div>
            </a>
            <a href="#faq">
              <div className=" mobile_title px40">FAQ</div>
            </a>
          </div>
          {/* <div className={isMobile ? "self-end" : "width-divide"}>
            <img
              src={isMobile ? mobileScrollUpBtn : scrollUpBtn}
              alt="scrollUpBtn"
              className="cursor-pointer"
              onClick={() => window.scrollTo(0, 0)}
            />
          </div> */}
        </div>

        <div className="flex items-center justify-between pt24 icons last_container an-14 vt-regular-text lightgray--text">
          <div className="grid-row-2-3">
            All Rights Reserved © Copyright 2025
          </div>
          <div className="social_container">
          <a href="https://twitter.com/WarlordsOnETH">
            <img src={twitterIcon} alt="twitterIcon" />
          </a>
          {/*  <img src={discordIcon} alt="discordIcon" className="px10" /> */}
          </div>
          <div className="flex items-center policy-section">
            <div>Privacy, Policy</div>
            <div className="pl57 mr23 policy-sub-section">Terms of Use</div>
          </div>
        </div>
      </div>
    </FooterWrapper>
  );
};

Footer.propTypes = {};

Footer.defaultProps = {};

export default Footer;
