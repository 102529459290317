import React, { FunctionComponent, useState, useEffect } from "react";
import styled from "styled-components";
import useDevice from "hooks/useDevice";
import {
  leaderCardBG, firstPlaceBadge, secondPlaceBadge, thirdPlaceBadge, elvesRankFlag, humansRankFlag, orcsRankFlag, coinIcon
} from "helper/constants";
import ActiveBox from "components/common/ActiveBox";

const ClanBoardCardWrapper = styled.div`
  .card {
    max-width: 360px;
    height: 482px;
    background-image: url(${leaderCardBG}) !important;
    background-size: cover;
    position: relative;
  }

  .badge{
    width: 133px;
    height: 40px;
    position: absolute;
    left: 114px;
    top: 49px;
  }

  .clanFlag{
    width: 131.99px;
    height: 157.56px;
    position: absolute;
    top: 114px;
    left: 26px;
  }

  .clanName{
    position: absolute;
    top: 156px;
    left: 170px;
    width: 189px;
    text-align: left;
    font-size: 36px;
    font-family: "Borken-console-bold";
  }

  .coinLogo{
    position: absolute;
    top: 206px;
    left: 170px;
    width: 24px;
    height: 21.55px;
  }

  .clanBalance{
    position: absolute;
    top: 208px;
    left: 201px;
    font-size: 16px;
    font-family: "Borken-console-bold";
  }

  .researchLabel{
    position: absolute;
    left: 24px;
    top: 303px;   
    font-size: 12px;
    font-family: "Borken-console-bold";
  }

  .researchLevel{
    position: absolute;
    left: 24px;
    top: 327px;
    width: 252.28px;
    
  }

  .attackLabel{
    position: absolute;
    left: 24px;
    top: 366.08px;   
    font-size: 12px;
    font-family: "Borken-console-bold";
  }

  .attackLevel{
    position: absolute;
    left: 24px;
    top: 390.04px;
    width: 252.28px;
    
  }

  .shape{
    height: 23.31px;
    width: 24.06px;
    margin-top: 0px;
  }

  .researchValue{
    position: absolute;
    left: 274.47px;
    top: 332.02px;
    font-size: 14px;
    font-family: "Borken-console-regular";
    width: 69px;
    text-align: right;
  }

  .attackValue{
    position: absolute;
    left: 274.47px;
    top: 395.06px;
    font-size: 14px;
    font-family: "Borken-console-regular";
    width: 69px;
    text-align: right;
  }
`;

interface ClanBoardCardProps {
  isModal?: boolean;
  rank?: number;
  clan?: string;
  balance?: number;
}

const ClanBoardCard: FunctionComponent<ClanBoardCardProps> = ({
  isModal = true,
  rank = 3,
  clan = "humans",
  balance = 0
}) => {
  const { isMobile } = useDevice();
  const [badgeImage, setBadgeImage] = useState(thirdPlaceBadge);
  const [clanFlag, setClanFlag] = useState(humansRankFlag);
  const [formattedBalance, setFormattedBalance] = useState('');
  const [attackColor, setAttackColor] = useState("#009EDB");

  // if (isMobile && isModal) {
  //   return (
  //     <ClanBoardCardWrapper n>

  //     </ClanBoardCardWrapper>
  //   );
  // }

  useEffect(() => {
    if (rank === 1) {
      setBadgeImage(firstPlaceBadge);
    } else if (rank === 2) {
      setBadgeImage(secondPlaceBadge);
    }

    if (clan === "elves") {
      setClanFlag(elvesRankFlag);
      setAttackColor("#0CAE48");
    } else if (clan === "orcs") {
      setClanFlag(orcsRankFlag);
      setAttackColor("#F61A29");
    }

    const formatted = balance.toLocaleString(); // Format the balance with commas
    setFormattedBalance(formatted);
  }, []);

  return (
    <ClanBoardCardWrapper>
      <div className="card">
        <img className="badge" alt="Rank Badge" src={badgeImage}></img>
        <div>
          <img className="clanFlag" alt="Clan Flag" src={clanFlag}></img>
        </div>
        <div>
          <div className="clanName">{clan.toUpperCase()}</div>
          <img className="coinLogo" alt="Coin Logo" src={coinIcon}></img>
          <div className="clanBalance">{formattedBalance}</div>
        </div>
        <div className="researchLabel">RESEARCH</div>
        <div className="researchLevel">
          <ActiveBox color={"#F1D36B"} limit={5} />
        </div>
        <div className="researchValue">1.8 | 3M</div>
        <div className="attackLabel">ATTACK</div>
        <div className="attackLevel">
          <ActiveBox color={attackColor} limit={5} />
        </div>
        <div className="attackValue">32%</div>
      </div>
    </ClanBoardCardWrapper>
  );
};

ClanBoardCard.propTypes = {};

ClanBoardCard.defaultProps = {};

export default ClanBoardCard;
