import React, { FunctionComponent } from "react";
import styled from "styled-components";
import { aboutImg, AST1 } from "helper/constants";

const AboutOurWorldWrapper = styled.div`
  .detail {
    max-width: 560px;
  }
  .about-img-container {
    img {
      width: 100%;
      height: 100%;
    }
  }
  .content {
    display: grid;
    grid-template-columns: 48% 48%;
    gap: 4%;
  }
  @media (max-width: 1150px) {
    .content-header {
      padding-left: 20px;
    }
    .content {
      padding: 0 20px 40px;
      p {
        margin: 0;
        padding-bottom: 20px;
      }
      p:last-child {
        padding: 0;
      }
      @media (max-width: 800px) {
        margin-top: 35px;
      }
      @media (max-width: 500px) {
        grid-template-columns: 100%;
        gap: 20px;
      }
    }
    @media (max-width: 575px) {
      .content-header {
        font-size: 28px !important;
        padding-bottom: 28px !important;
        text-align:center;
      }
      .about-img-container {
        img {
          height: 176px !important;
          width: 100%;
          object-fit: cover;
        }
      }
      .detail {
        font-size: 20px !important;
        max-width: 330px !important;
        text-align:center;
        padding-left: 0px;
        padding-right: 0px;
        max-width: 100% !important;
      }
    }
  }
`;

const AST1Wrapper = styled.div`
  @media (max-width: 575px) {
    display: none;
  }
`;

interface AboutOurWorldProps {}

const AboutOurWorld: FunctionComponent<AboutOurWorldProps> = () => {
  return (
    <div className="relative">
      <AST1Wrapper>
        <img
          src={AST1}
          alt="AST1"
          className="absolute"
          style={{ top: "250px" }}
        />
      </AST1Wrapper>
      <AboutOurWorldWrapper className="app-container" id="about">
        <div className="an-48 b-bold-text pb33 mt55 content-header">
          About our World
        </div>
        <div className="about-img-container">
          <img src={aboutImg} alt="aboutImg" />
        </div>
        <div className="flex mt64 content">
          <div className="an-24 vt-regular-text detail lightgray--text">
            <p>
            The once-friendly Human, Orc, and Elf nations are now locked in a fierce struggle to gather the last bits of AQUIS to escape the doomed planet of Zenthia.
            </p>
            <p>
            Our wise mages and wizards have uncovered ancient secrets that reveal the power of AQUIS to help survive the current world. 
            As a cunning warlord, you must decide how to allocate your resources – will you focus on fortifying your defenses, launching daring attacks, or strategizing your nation's survival?
            </p>
          </div>
          <div className="an-24 vt-regular-text detail lightgray--text">
            <p>
            Assemble your clan and build your force against other warlords to ensure the survival of your nation, and may AQUIS rain down upon you in abundance! 
             
            </p>
            <p>
            Brace yourself for a thrilling adventure filled with magic, mayhem, and the ultimate battle for survival.
            Are you ready to lead your people to survival? The fate of your nation is in your hands!
            </p>
          </div>
        </div>
      </AboutOurWorldWrapper>
    </div>
  );
};

AboutOurWorld.propTypes = {};

AboutOurWorld.defaultProps = {};

export default AboutOurWorld;



