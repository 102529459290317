import React, { FunctionComponent } from "react";
import styled from "styled-components";
import { Modal } from "antd";
import useDevice from "hooks/useDevice";
import {
  continueBtn,
  viewTransactionBtn,
  modalBorder,
  mobileModalBorder,
  modalBackground,
  mobileModalBackground,
  unstakeBtn,
} from "helper/constants";

const StakeRequestModalWrapper = styled.div``;

interface StakeRequestModalProps {
  stakingVisible?: any;
  setStakingVisible?: any;
  requestIcon?: any;
  requestTitle?: any;
  requestDesc?: any;
  requestUnstakeBtn?: boolean;
  setUnstakingVisible?: any;
  showTransactionIcon: boolean;
}

const StakeRequestModal: FunctionComponent<StakeRequestModalProps> = ({
  stakingVisible,
  setStakingVisible,
  requestIcon,
  requestTitle,
  requestDesc,
  requestUnstakeBtn = false,
  setUnstakingVisible,
  showTransactionIcon = true
}) => {
  const { isMobile } = useDevice();

  return (
    <StakeRequestModalWrapper>
      <Modal
        centered
        visible={stakingVisible}
        onOk={() => setStakingVisible(false)}
        onCancel={() => setStakingVisible(false)}
        footer={null}
        className="staking-request-modal"
      >
        <div className="relative mobile_position">
          <img
            src={isMobile ? mobileModalBorder : modalBorder}
            alt="modalBorder"
            className="absolute modal_border"
            style={{ zIndex: 1 }}
          />
          <img
            src={isMobile ? mobileModalBackground : modalBackground}
            alt="modalBackground"
            className="absolute"
          />
        </div>
        <div
          className={`${isMobile ? "text-center" : "ma30"} relative main_part`}
        >
          <img
            src={requestIcon}
            alt="icon"
            width="64px"
            height="64px"
            className="flex margin-auto"
          />
          <div
            className={`${
              isMobile ? "an-20 pt20 margin-auto mobile_width" : "an-27 mt25"
            } b-bold-text text-center`}
          >
            {requestTitle}
          </div>
          <div
            className={`${
              isMobile ? "pt15 margin-auto" : "mt15"
            } an-19 vt-regular-text lightgray--text text-center mobile_width`}
          >
            {requestDesc}
          </div>
            
          {showTransactionIcon ? (requestUnstakeBtn ? (
            <div className="flex justify-center pt20">
              <img
                src={unstakeBtn}
                alt="unstakeBtn"
                onClick={() => setUnstakingVisible(true)}
                className="z-index1"
              />
            </div>
          ) : (
            <div
              className={`${
                isMobile ? "mt15 mobile_btns" : "mt25"
              } flex justify-between`}
            >
              <img
                src={continueBtn}
                alt="continueBtn"
                className={`${isMobile ? "pb15 margin-auto" : ""} cursor-pointer z-index1`}
                onClick={() => setStakingVisible(false)}
              />
              <img src={viewTransactionBtn} alt="viewTransactionBtn" />
            </div>
          )) :             <div
          className={`${
            isMobile ? "mt15 mobile_btns" : "mt25"
          } flex justify-center`}
        >
          <img
            src={continueBtn}
            alt="continueBtn"
            className={`${isMobile ? "pb15 margin-auto" : ""} cursor-pointer z-index1`}
            onClick={() => setStakingVisible(false)}
          />
        </div>}
        </div>
      </Modal>
    </StakeRequestModalWrapper>
  );
};

StakeRequestModal.propTypes = {};

StakeRequestModal.defaultProps = {};

export default StakeRequestModal;
