import React, { FunctionComponent } from "react";
import styled from "styled-components";
import useDevice from "hooks/useDevice";
import {
  evlesFlag,
  // evles,
  // humans,
  // orcs,
  evlesIcon,
  humansFlag,
  humansIcon,
  orcsFlag,
  orcsIcon,
} from "helper/constants";

import { nfdId2, buyNew, nfdId } from "helper/constants";

const FirstWrapper = styled.div`
  .contet-wrapper {
    /* display: grid;
    grid-template-columns: 68% 28%;
    gap: 4%; */
    display: grid;
    grid-template-columns: 58% 38%;
    gap: 5%;

    @media (max-width: 1000px) {
      grid-template-columns: 100%;
      gap: 0%;
    }
  }
  .clan-detail {
    @media (max-width: 1000px) {
      margin-top: 40px;
    }
  }
  .clip-img {
    width: 100%;
    clip-path: polygon(50% 99%,99% 73%,99% 0,0 0,0 73%);
  }
  .game-img-container {
    display: grid;
    grid-template-columns: 32% 32% 32%;
    gap: 2%;
  }
  .card_box {
    width: 210px;
    height: 226px;
  }
  .humans {
    background: radial-gradient(#92e8c0, #4fa4b800);
  }
  .orcs {
    background: radial-gradient(#ff8933, #8f4d5700);
    position: relative;
  }
  .orcs:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    clip-path: polygon(50% 100%, 100% 75%, 100% 0, 0 0, 0 75%);
  }
  .evles {
    background: radial-gradient(#51a1ff, #4fa4b800);
  }
  @media (max-width: 575px) {
    padding: 20px;
    .gs_title {
      font-size: 28px !important;
      margin-top: 24px !important;
      text-align: center;
    }
    .contet-wrapper {
      margin-top: 52px !important;
      display: block !important;
      text-align: center;
    }
    .choose-title {
      font-size: 14px !important;
      padding-left: 10px !important;
    }
    .game_title {
      font-size: 28px !important;
    }
    .game_desc {
      margin-top: 20px !important;
      font-size: 20px !important;
    }
    .mobile_flag {
      width: 100%;
    }
    .clip-img {
      margin-bottom: 8px !important;
    }
    /* .card_box {
      width: 105px;
      height: 113px;
    } */
  }
`;

interface FirstProps {}

const First: FunctionComponent<FirstProps> = () => {
  const { isMobile } = useDevice();

  return (
    <FirstWrapper className="app-container" id="survivaljourney">
      <div className="an-58 b-bold-text mt92 gs_title">SURVIVAL JOURNEY</div>

      <div className="flex mt62 contet-wrapper">
        <div className="game-img-container">

          <div className="an-24 b-bold-text text-center">
            {/* <img src={humansIcon} alt="humansIcon" className="clip-img humans mb15" />
            <img src={humansIcon} alt="humansIcon" className="card_box" /> */}
            <div className="card_title flex items-center justify-center">
              <img src={buyNew} alt="humansFlag" className="mobile_flag" />
              <div className="an-24 b-bold-text text-center pl15 choose-title">
                
              </div>
            </div>
          </div>
          <div className="an-24 b-bold-text text-center">
            {/* <img src={orcsIcon} alt="orcsIcon" className="clip-img orcs mb15" />
            <img src={orcsIcon} alt="orcsIcon" className="card_box" /> */}
            <div className="card_title flex items-center justify-center">
              <img src={nfdId} alt="orcsFlag" className="mobile_flag" />
              <div className="an-24 b-bold-text text-center pl10 choose-title">
                
              </div>
            </div>
          </div>
          <div className="an-24 b-bold-text text-center">
            {/* <img src={evlesIcon} alt="evlesIcon" className="clip-img evles mb15" />
            <img src={evlesIcon} alt="evlesIcon" className="card_box" /> */}
            <div className="card_title flex items-center justify-center">
              <img src={nfdId2} alt="evlesFlag" className="mobile_flag" />
              <div className="an-24 b-bold-text text-center pl10 choose-title">
                
              </div>
            </div>
          </div> 
        </div>

        <div className={isMobile? "clan-detail" : "clan-detail"}>
          <div className="an-36 b-bold-text game_title">
            1. Mint Your NFT
          </div>
          <div className="an-24 vt-regular-text lightgray--text mt30 game_desc">
          {/* Your NFTs aren't just nice art, they're your ticket to power. The more you collect, the more influence you wield. Allocate your hard-earned AQUIS to reap even greater rewards. The number of NFTs from a nation in your possession determines the total amount of AQUIS you can allocate with your wallet. */}
          Your NFTs aren't just digital art; they're your keys to the kingdom. The more you mint, the more sway you hold over Zenthia. Use your AQUIS wisely to amplify your power. The quantity of NFTs from each nation you own directly correlates with how much AQUIS you can allocate from your wallet. More NFTs, more might, more AQUIS.
          <br/>


          </div>
        </div>
      </div>
    </FirstWrapper>
  );
};

First.propTypes = {};

First.defaultProps = {};

export default First;
