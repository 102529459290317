import React, { FunctionComponent, useState } from "react";
import styled from "styled-components";
import useDevice from "hooks/useDevice";
import {
  coinIcon,
  applyMintBg,
  applyMintBtn,
  minusIcon,
  plusIcon,
  cardMintGif,
  mintShadow,
  mobileMintShadow,
} from "helper/constants";
import TopHeader from "components/common/TopNavigation";

const MintingWrapper = styled.div`
  .coin_icon {
    width: 96px;
    height: 96px;
  }
  .left_part {
    width: 418px;
  }
  .min_data {
    position: absolute;
    top: 40%;
    left: 42%;
    transform: translate(-50%, -50%);
    width: 275px;
  }
  .applymint_btn {
    position: absolute;
    left: 13%;
    display: flex;
    bottom: -9%;
  }
  .mb_shadow {
    display: none;
  }
  @media (max-width: 575px) {
    .web_shadow {
      display: none;
    }
    .mb_shadow {
      width: 100%;
      bottom: 0;
    }
    .mb_first_part {
      flex-direction: column-reverse;
    }
    .cardmint_gif {
      width: 201px;
      height: 241px;
      margin: 0 auto;
    }
    .min_data {
      left: 50% !important;
    }
    .coin_icon {
      width: 64px;
      height: 64px;
    }
  }
`;

export const NftCoinMint = () => {
  const { isMobile } = useDevice();

  return (
    <div
      className={`${
        isMobile ? "mt27 mx20" : "mt40 justify-center"
      } flex items-center`}
    >
      <img
        src={coinIcon}
        alt="coinIcon"
        className={`${isMobile ? "mr25" : "mr10"} coin_icon`}
      />
      <div>
        <div className={`${isMobile ? "an-20" : "an-28"} b-bold-text`}>
          0 / 10,000
        </div>
        <div className={`${isMobile ? "an-20" : "an-24"} vt-regular-text`}>
          NFT’s Minted
        </div>
      </div>
    </div>
  );
};

interface MintingProps {}

const Minting: FunctionComponent<MintingProps> = () => {
  const { isMobile } = useDevice();
  const [mintCount, setMintCount] = useState(0);

  function increaseMintCount(){
      if(mintCount <= 9){
        setMintCount(mintCount + 1);
      }
  };

  function decreaseMintCount(){
    if(mintCount > 0){
      setMintCount(mintCount - 1);
    }
};

  return (
    <MintingWrapper>
      <TopHeader />
      <img src={mintShadow} alt="mintShadow" className="absolute web_shadow" />
      <div className="app-container pb35 relative">
        {!isMobile && <NftCoinMint />}
        <div className="flex justify-between mt20 mb_first_part">
          <div
            className={`${
              isMobile ? "px23 z-index1" : ""
            } flex justify-center items-center`}
          >
            <div className="left_part">
              <div
                className={`${
                  isMobile ? "an-20 mt27" : "an-24"
                } vt-regular-text`}
              >
                For free, first 100 users
              </div>
              <div
                className={`${isMobile ? "an-28" : "an-36"} b-bold-text mt25`}
              >
                Became first player, participate in our Minting
              </div>
              <div className="mt30 relative">
                <img src={applyMintBg} alt="applyMintBg" />
                <div className="flex min_data">
                  <img src={minusIcon} alt="minusIcon" onClick={decreaseMintCount}/>
                  <div className="mx25">
                    <div className="an-36 b-bold-text text-center">{mintCount}</div>
                    <div
                      className="an-20 vt-regular-text"
                      style={{ marginTop: "-5px" }}
                    >
                      Max 10 mints
                    </div>
                  </div>
                  <img src={plusIcon} alt="plusIcon" onClick={increaseMintCount}/>
                </div>
                <img
                  src={applyMintBtn}
                  alt="applyMintBtn"
                  className="applymint_btn"
                />
              </div>
            </div>
          </div>
          {isMobile && <NftCoinMint />}
          <img src={cardMintGif} alt="cardMintGif" className="cardmint_gif" />
        </div>
        <img
          src={mobileMintShadow}
          alt="mobileMintShadow"
          className="absolute mb_shadow"
        />
      </div>
    </MintingWrapper>
  );
};

Minting.propTypes = {};

Minting.defaultProps = {};

export default Minting;
