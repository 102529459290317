import React, { FunctionComponent } from "react";
import styled from "styled-components";
import useDevice from "hooks/useDevice";
import {
  buyNewBtn,
  coinIcon,
  colorBackground,
  nftBorder,
  mobileNftBorder,
  mobileColorBackground,
  activeNftBorder,
  mobileActiveBorder,
} from "helper/constants";

const StakeNftCardWrapper = styled.div`
  .card {
    width: 260px;
    height: 393px;
  }
  .active_border {
    position: absolute;
    top: -14px;
    z-index: 2;
    bottom: 0;
    right: 0;
    left: 0;
  }
  .background__img {
    width: 260px;
    height: 382px;
    object-fit: cover;
    left: 0;
    top: -14px;
    bottom: 0;
    right: 0;
    z-index: 1;
  }
  .card_icon {
    top: 10px;
    left: 20px;
    right: 0;
    bottom: 0;
    z-index: 2;
    /* clip-path: polygon(49% 99%,99% 73%,99% 0, 1% 0,1% 73%); */
    width: 219px;
    height: 217px;
  }
  .card_title {
    z-index: 1;
    bottom: 60px;
    left: 22px;
  }
  .buy_new_btn {
    position: absolute;
    z-index: 2;
    bottom: 24%;
    left: 17%;
  }
  @media (max-width: 575px) {
    .background__img {
      /* width: 133.76px;
      height: 330px; */
    }
    .mobile_bg {
      z-index: 111;
      position: relative;
    }
    .mobile_color_bg {
      width: 312px;
      position: absolute;
      left: 9px;
    }
    .mobile_card {
      max-width: 330px;
      margin: 0 auto;
      margin-bottom: 10px !important;
    }
    .card_icon_mobile {
      width: 98px;
      height: 98px;
      top: 20px !important;
      left: 40px !important;
    }
    .mobile_card_title {
      top: 20px !important;
      right: 75px !important;
    }
  }
`;

interface StakeNftCardProps {
  cardIcon?: any;
  nftId?: any;
  Price?: any;
  buyNewCard?: any;
  isModal?: boolean;
  handleClick?: Function;
  index: number;
  active?: any;
}

const StakeNftCard: FunctionComponent<StakeNftCardProps> = ({
  cardIcon,
  nftId,
  Price,
  buyNewCard = false,
  isModal = true,
  handleClick,
  index,
  active,
}) => {
  const { isMobile } = useDevice();

  if (isMobile && isModal) {
    return (
      <StakeNftCardWrapper>
        <div
          className="relative mobile_card"
          onClick={() => handleClick && handleClick(index)}
        >
          <img
            src={active?.includes(index) ? mobileActiveBorder : mobileNftBorder}
            alt="nftBorder"
            className="mobile_bg"
          />
          <img
            src={mobileColorBackground}
            alt="colorBackground"
            className="mobile_color_bg"
          />
          <img
            src={cardIcon}
            alt="cardIcon"
            className="absolute card_icon_mobile"
          />
          <div className="mobile_card_title absolute">
            <div className="an-20 b-bold-text">#{nftId}</div>
            <div className="an-16 vt-regular-text lightgray--text">
              BATTLE PROWESS:
            </div>
            <div className="flex pt5">
              <img src={coinIcon} alt="coinIcon" />
              <div className="an-20 b-bold-text pl5">{Price}</div>
            </div>
          </div>
        </div>
      </StakeNftCardWrapper>
    );
  }
  return (
    <StakeNftCardWrapper>
      <div
        className="card relative"
        onClick={() => handleClick && handleClick(index)}
      >
        <img
          src={active?.includes(index) ? activeNftBorder : nftBorder}
          alt="nftBorder"
          className="active_border"
        />
        <img
          src={colorBackground}
          alt="colorBackground"
          className="absolute background__img"
        />
        <img src={cardIcon} alt="cardIcon" className="absolute card_icon" />
        {!buyNewCard ? (
          <div className="card_title absolute">
            <div className={`${isMobile ? "an-16" : "an-20"} b-bold-text`}>
              NFT ID: #{nftId}
            </div>
            <div
              className={`${
                isMobile ? "an-14" : "an-16"
              } vt-regular-text lightgray--text pt10`}
            >
              BATTLE PROWESS:
            </div>
            <div className="flex pt5">
              <img src={coinIcon} alt="coinIcon" />
              <div
                className={`${
                  isMobile ? "an-16" : "an-20"
                } b-bold-text pl5 pt5`}
              >
                {Price}
              </div>
            </div>
          </div>
        ) : (
          <div>
            <img
              src={buyNewBtn}
              alt="buyNewBtn"
              className="cursor-pointer buy_new_btn"
            />
          </div>
        )}
      </div>
    </StakeNftCardWrapper>
  );
};

StakeNftCard.propTypes = {};

StakeNftCard.defaultProps = {};

export default StakeNftCard;
