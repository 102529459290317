import React, { FunctionComponent, useEffect, useState } from "react";
import styled from "styled-components";
import { Modal } from "antd";
import useDevice from "hooks/useDevice";
import { useSelector } from "react-redux";
import {
  userProfile,
  coinIcon,
  buyBtn,
  allocateCoinsBtn,
  greenElvesIcon,
  redOrcsIcon,
  blueHumansIcon,
  researchIcon,
  mobileAllocateCoinsBtn,
  elvesShieldIcon,
  humansShieldIcon,
  orcsShieldIcon
} from "helper/constants";
import ActiveBox from "components/common/ActiveBox";
import AllocateCoinModal from "components/gameScreen/AllocateCoinModal";
import { getClanValue } from "redux/selectors/demo";
import { IClanValue } from "interface/demo";
import { Dictionary } from "@reduxjs/toolkit";

const AllocateCoinCardWrapper = styled.div`
  .buy-card {
    width: 260px;
    background: #393964;
  }
  .percentage_part {
    max-width: 860px;
    width: 100%;
  }
  @media (max-width: 575px) {
    .buy-card {
      max-width: 575px;
      width: 100%;
    }
    .wallet-section {
      max-width: 240px;
    }
    .main-card {
      flex-direction: column;
      align-items: center;
      margin: 0 18px;
      padding-top: 20px !important;
    }
    .percentage_part {
      margin-left: 0px;
      max-width: 575px;
    }
    .text-16 {
      font-size: 16px !important;
    }
    .text-14 {
      font-size: 14px !important;
    }
  }
`;

interface AllocateCoinCardProps {stakeAllowed:number, unstakedCoinBalance:number}

const AllocateCoinCard: FunctionComponent<AllocateCoinCardProps> = ({stakeAllowed, unstakedCoinBalance}) => {
  const [allocateCoinsModal, setAllocateCoinsModal] = useState(false);
  const clanValue: IClanValue | null = useSelector(getClanValue);
  const { isMobile } = useDevice();
  const [cardTitles, setCardTitles] = useState(["Attack Elves", "Attack Orcs", "Defenders"]);
  const [cardIcons, setCardIcons] = useState([greenElvesIcon, redOrcsIcon, humansShieldIcon]);
  const [iconAlts, setIconAlts] = useState(["Elves Icon", "Orcs Icon", "Human Shield Icon"]);
  const [boxColors, setBoxColors] = useState(["#0CAE48","#F61A29","#009EDB"]);
  const [stakedTags, setStakedTags] = useState(["","",""]);
  const [cardStaked, setCardStaked] = useState([0, 0, 0]);
  const [stakeLimit, setMaxStakeLimit] = useState(11800);
  const [stakedBoxes, setStakedBoxes] = useState([0, 0, 0]);
  const [acquisAllocated, setAcquisAllocated] = useState(0);

  const showAllocateModal = () => {
    setAllocateCoinsModal(true);
  };

  const allocateClose = () => {
    setAllocateCoinsModal(false);
  };

  function updateStaked (stakedValues: Record<string, number>, stakedTitles: Record<string, string>){
    console.log("Update me");
    console.log(stakedValues);
    console.log(stakedTitles);
    console.log(cardTitles);
    const updatedArray = [...cardStaked];
    let total = 0;
    Object.entries(stakedTitles).forEach(([key, value], index) => {
        if(value.length > 0){
          const cardIndex = cardTitles.findIndex(
            (item) => item.toLowerCase() === value.toLowerCase()
          );

          
          updatedArray[cardIndex] = stakedValues[key];
          total += updatedArray[cardIndex];
        }
    });

    setCardStaked(updatedArray);

    const tags = [];
    const boxFills = [];
    for(let i = 0; i < 3; i++){
      const percStaked = Math.floor(updatedArray[i] * 100 / stakeLimit);
      const amtStaked = updatedArray[i];
      const boxesToFill = Math.floor(percStaked/10.0);
      const stakeTag = amtStaked.toString() + "-" + percStaked.toString() + "%";
      tags.push(stakeTag);
      boxFills.push(boxesToFill);
    };

    setAcquisAllocated(total);
  };

  useEffect(() => {
    console.log("************Clan Value ********")
    console.log(clanValue);
    if(clanValue?.title==='ORCS'){
      setCardTitles(["Attack Elves", "Attack Humans", "Defenders"]);
      setCardIcons([greenElvesIcon, blueHumansIcon, orcsShieldIcon]);
      setIconAlts(["Elves Icon", "Humans Icon", "Orcs Shield Icon"]);
      setBoxColors(["#0CAE48","#009EDB","#F61A29"]);
    }else if(clanValue?.title==='ELVES'){
      setCardTitles(["Attack Humans", "Attack Orcs", "Defenders"]);
      setCardIcons([blueHumansIcon, redOrcsIcon, elvesShieldIcon])
      setIconAlts(["Humans Icon", "Orcs Icon", "Elves Shield Icon"]);
      setBoxColors(["#009EDB","#F61A29","#0CAE48"]);
    };

    const tags = [];
    const boxFills = [];
    for(let i = 0; i < 3; i++){
      const percStaked = Math.floor(cardStaked[i] * 100 / stakeLimit);
      const amtStaked = cardStaked[i];
      const boxesToFill = Math.floor(percStaked/10.0);
      const stakeTag = amtStaked.toString() + "-" + percStaked.toString() + "%";
      tags.push(stakeTag);
      boxFills.push(boxesToFill);
    };
    
    setStakedTags(tags);
    setStakedBoxes(boxFills);
  }, [cardStaked, clanValue, stakeLimit]);

  return (
    <AllocateCoinCardWrapper>
      <div className="pt33 flex main-card">
        <div className="buy-card pa15">
          <div className="flex items-center">
            <img src={userProfile} alt="userProfile" />
            <div className={isMobile ? "pl15" : "pl20"}>
              <div className="an-16 vt-regular-text lightgray--text">
                Wallet:
              </div>
              <div className="an-16 b-bold-text mt5">0x93....a32F9A</div>
            </div>
          </div>

          <div className={`${isMobile ? "mt10" : "mt40"} flex items-center justify-between`}>
            <div>
              <div className="an-16 vt-regular-text lightgray--text text-14">
              WALLET BALANCE:
              </div>
              <div className="flex pt5">
                <img
                  src={coinIcon}
                  alt="coinIcon"
                />
                <div className="an-20 b-bold-text pl6 pt5">{unstakedCoinBalance}</div>
              </div>
            </div>
            <img src={buyBtn} alt="buyBtn" className="cursor-pointer" height={isMobile ? 34 : ''} />
          </div>

          <div className={`${isMobile ? 'mt20' : 'mt30'} flex justify-between wallet-section`}>
            <div>
              <div className="an-16 vt-regular-text lightgray--text text-14">
                $ACQUIS ALLOCATED:
              </div>
              <div className="flex pt5">
                <img
                  src={coinIcon}
                  alt="coinIcon"
                />
              <div className="an-20 b-bold-text pl6 text-16">{acquisAllocated}</div>
              </div>
            </div>
            <div>
              <div className="an-16 vt-regular-text lightgray--text text-14">
                MAXIMUM PROWESS:
              </div>
              <div className="flex pt5">
                <img
                  src={coinIcon}
                  alt="coinIcon"
                />
              <div className="an-20 b-bold-text pl6 text-16"> {stakeAllowed}</div>
              </div>
            </div>
          </div>

          <div className="flex justify-center">
            <img
              src={isMobile ? mobileAllocateCoinsBtn : allocateCoinsBtn}
              alt="allocateCoinsBtn"
              className={`${isMobile ? 'mt15' : 'mt21'} cursor-pointer`}
              onClick={showAllocateModal}
            />
            <Modal
              visible={allocateCoinsModal}
              footer={null}
              className="stake-nft-modal"
              width={"100%"}
            >
              <AllocateCoinModal allocateClose={allocateClose} updateStaked={updateStaked}/>
            </Modal>
          </div>
        </div>
        {/* percentage code  */}
        <div className="percentage_part ml45 mt40">
          <div>
            <div className="flex justify-between items-center ml40">
              <div className="an-20 b-bold-text text-16">{cardTitles[0]}</div>
              <div className="flex">
                <img src={coinIcon} alt="coinIcon" />
                <div className="an-20 b-bold-text pl6 pt5 text-16">
                  {stakedTags[0]}
                </div>
              </div>
            </div>
            <div className="flex">
              <img src={cardIcons[0]} alt={iconAlts[0]} className="mr15"/>
              <ActiveBox color={boxColors[0]} limit={stakedBoxes[0]} />
            </div>
          </div>
          <div className="mt30">
            <div className="flex justify-between items-center ml40">
              <div className="an-20 b-bold-text text-16">{cardTitles[1]}</div>
              <div className="flex">
                <img src={coinIcon} alt="coinIcon" />
                <div className="an-20 b-bold-text text-16 pl6 pt5">
                {stakedTags[1]}
                </div>
              </div>
            </div>
            <div className="flex">
              <img src={cardIcons[1]} alt={iconAlts[1]}  className="mr15" />
              <ActiveBox color={boxColors[1]} limit={stakedBoxes[1]} />
            </div>
          </div>
          <div className="mt30">
            <div className="flex justify-between items-center ml40">
              <div className="an-20 b-bold-text text-16">{cardTitles[2]}</div>
              <div className="flex">
                <img src={coinIcon} alt="coinIcon" />
                <div className="an-20 b-bold-text text-16 pl6 pt5">
                {stakedTags[2]}
                </div>
              </div>
            </div>
            <div className="flex">
              <img src={cardIcons[2]} alt={iconAlts[2]}  className="mr11" />
              <ActiveBox color={boxColors[2]} limit={stakedBoxes[2]} />
            </div>
          </div>
        </div>
      </div>
    </AllocateCoinCardWrapper>
  );
};

AllocateCoinCard.propTypes = {};

AllocateCoinCard.defaultProps = {};

export default AllocateCoinCard;

