import React, { FunctionComponent, useState } from "react";
import styled from "styled-components";
// import { galaxyGif, galaxyVideo } from "helper/constants";
import { galaxyGif } from "helper/constants";

const FourthWrapper = styled.div`
  padding-top: 80px;
  .clan_plots {
    max-width: 600px;
    padding-bottom: 40px;
  }
  .portal-detail {
    max-width: 460px;

    @media (max-width: 900px) {
      max-width: unset;
      width: 100%;
      margin: 0;
      margin-top: 40px;
    }
  }

  @media (max-width: 900px) {
    .portal-container {
      flex-direction: column-reverse;
      padding: 20px;
    }
  }
  
  @media (max-width: 600px) {
    .clan_plots {
      max-width: 100vw;
      padding-bottom: 40px;
    }
  }

  @media (max-width: 575px) {
    padding-top: 15px !important;
    .game_title {
      font-size: 28px !important;
    }
    .game_desc {
      margin-top: 20px !important;
      font-size: 20px !important;
    }
    .portal-detail {
      margin-top: 20px;
    }
    .portal-container {
      padding: 20px 20px 50px;
      text-align: center;
    }
    .galaxy_img {
      width: 257px;
    }
  }
`;

interface FourthProps {}

const Fourth: FunctionComponent<FourthProps> = () => {
  
  return (
    <FourthWrapper className="app-container">
      <div className="portal-container flex items-center">
        <div className="portal-detail mr100">
          <div className="an-36 b-bold-text game_title">
            4. Unlock Portal
          </div>
          <div className="an-24 vt-regular-text lightgray--text mt30 game_desc">
          {/* Allocate enough AQUIS as a nation to open your portal, allowing you to mint Warlords of new nations on another planet to further your conquest for AQUIS. */}
          Your portal is waiting - but it'll cost you AQUIS. Once opened, the possibilities are endless: new planets, new Warlords, and a flood of AQUIS.
          </div>
        </div>
        <div className="flex relative">
          <img src={galaxyGif} alt="clanPlots" className="clan_plots" style={{background: "url('/assets/halo_back.png')", backgroundRepeat: 'no-repeat', backgroundPosition: 'center'}}/>
        </div>
      </div>
    </FourthWrapper>
  );
};

Fourth.propTypes = {};

Fourth.defaultProps = {};

export default Fourth;
