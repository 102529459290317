import React, { FunctionComponent, useState } from "react";
import styled from "styled-components";
import useDevice from "hooks/useDevice";
// import { Modal } from "antd";
import { Slider } from "antd";
import {
  closeBtn,
  stakeCoinBtn,
  holderIcon,
  nftCard1Img,
  nftCard2Img,
  nftCard3Img,
  lightBlueSliderBtn,
  researchIcon,
  unstakeBtn,
  coinIcon,
} from "helper/constants";
import StakeNftCard from "components/common/StakeNftCard";
import StakeRequestModal from "components/gameScreen/StakeRequestModal";
// import StakeSuccessfull from "components/stake/StakeSuccessfull";
import { CardItem  } from "interface/demo";

const StakeNftWrapper = styled.div`
  margin-top: -35px;
  .stak_desc {
    max-width: 491px;
  }
  .close_btn {
    position: absolute;
    top: 10px;
    right: -19px;
  }
  .stake_slider {
    max-width: 650px;
    margin: 0 auto;
  }
  @media (max-width: 575px) {
    .stakcards {
      flex-direction: column;
    }
    .mobile-container {
      max-width: 500px;
    }
    .heading {
      font-size: 28px !important;
    }
    .sub-heading {
      font-size: 20px !important;
    }
    .close_btn {
      top: -50px;
      right: 28px;
    }
    .stak_desc {
      width: 256px;
      line-height: 100%;
    }
  }
`;

interface StakeNftProps {
  nftClose?: any;
  isUnstake?: any;
  nftCards?: CardItem[];
  unstakedCoinBalance: number
}

const StakeNft: FunctionComponent<StakeNftProps> = ({
  nftClose,
  isUnstake,
  nftCards = [],
  unstakedCoinBalance
}) => {
  // const [stakeSuccessModal, setStakeSuccessModal] = useState(false);
  const [stakingVisible, setStakingVisible] = useState(false);
  const [unstakingVisible, setUnstakingVisible] = useState(false);
  // const [active, setActive] = useState<any>([]);
  const { isMobile } = useDevice();
  const [currentPercentage, setCurrentPercentage] = useState("0-0%");

  // Here is the old code if future need
  // const showSuccessModal = () => {
  //   setStakeSuccessModal(true);
  // };

  // const successClose = () => {
  //   setStakeSuccessModal(false);
  // };

  const handleStakeEvent = () => {
    nftClose();
    setStakingVisible(true);
    setUnstakingVisible(false);
    // showSuccessModal();
  };

  const handleChange = (value: number) => {
    const lim = unstakedCoinBalance;
    const per = Math.floor(value * 100.0/lim);

    setCurrentPercentage(value.toString() + "-" + per.toString() + "%");
    // Add your logic here based on the new value
  };

  // select-unselect old flow code
  // const handleClick = (index: number) => {
  //   if (active.includes(index) && isUnstake) {
  //     const newActive = active.filter((a: number) => a !== index);
  //     setActive(newActive);
  //   } else {
  //     setActive([...active, index]);
  //   }
  // };

  return (
    <>
      <StakeNftWrapper>
        <div className="app-container mobile-container">
          <div className="an-48 heading b-bold-text text-center relative">
            <div className={isMobile ? "pt10" : ""}>
              {isUnstake ? "unstake your Coins" : "Stake your Coins"}
            </div>
            <img
              src={closeBtn}
              alt="closeBtn"
              className="close_btn cursor-pointer"
              onClick={nftClose}
            />
          </div>
          <div className="an-24 sub-heading vt-regular-text lightgray--text pt5 text-center margin-auto stak_desc">
            After staking, your coins would be available to play
          </div>
          <div
            className={`${
              isMobile ? "pt35" : "pt55"
            } stakcards flex justify-center`}
          >
            {nftCards.map((data, index) => (
              index < 4 && (
                <div className={index > 0 && !isMobile ? "ml40" : ""} key={index}>
                  <StakeNftCard
                    cardIcon={data.cardIcon}
                    nftId={data.nftId}
                    Price={data.price}
                    index={index + 1}
                    // handleClick={handleClick}
                    // active={active}
                  />
                </div>
              )
            ))}
          </div>
          <div className="stake_slider pt20">
            <div className="flex justify-between">
              <div className="an-16 b-bold-text">Stake</div>
              <div className="flex items-center">
                <img
                  src={coinIcon}
                  alt="coinIcon"
                  className="mr6"
                  width="16px"
                  height="14px"
                />
                <div className="an-16 b-bold-text pt5">{currentPercentage}</div>
              </div>
            </div>
            <Slider
              min={0}
              max={unstakedCoinBalance}
              trackStyle={{
                backgroundColor: "#5DD1DF",
                border: "2px solid #44B8CD",
                borderRadius: "0px",
              }}
              onChange={handleChange}
              handleStyle={{
                marginTop: "-34px",
                backgroundSize: "auto",
                border: "none",
                borderRadius: "0px",
                background: `url(${lightBlueSliderBtn})`,
                width: "73px",
                height: "73px",
              }}
              className="stake_coin_slider"
            />
          </div>

          {isUnstake ? (
            <img
              src={unstakeBtn}
              alt="unstakeBtn"
              className={`${
                isMobile ? "pt25" : "pt35"
              } pb20 flex margin-auto cursor-pointer`}
              onClick={handleStakeEvent}
            />
          ) : (
            <img
              src={stakeCoinBtn}
              alt="stakeCoinBtn"
              className={`${
                isMobile ? "pt25" : "pt35"
              } pb20 flex margin-auto cursor-pointer`}
              onClick={handleStakeEvent}
            />
          )}
        </div>
      </StakeNftWrapper>
      {isUnstake && !unstakingVisible ? (
        <StakeRequestModal
          stakingVisible={stakingVisible}
          setStakingVisible={setStakingVisible}
          requestIcon={holderIcon}
          requestTitle="Are you sure you want to unstake?"
          requestDesc="Please confirm you want unstake your monke/booster by clicking Unstake."
          requestUnstakeBtn={true}
          setUnstakingVisible={setUnstakingVisible}
          showTransactionIcon={true}
        />
      ) : (
        <StakeRequestModal
          stakingVisible={stakingVisible}
          setStakingVisible={setStakingVisible}
          requestIcon={researchIcon}
          requestTitle={`Your ${
            isUnstake ? "unstaking" : "staking"
          }  request has been successfully created`}
          requestDesc="You can see the transaction status in your wallet."
          showTransactionIcon={true}
        />
      )}
      {/* Here is the old code if future need  */}
      {/* <Modal
        visible={stakeSuccessModal}
        footer={null}
        className="stake-nft-modal"
        width={"100%"}
      >
        <StakeSuccessfull successClose={successClose} />
      </Modal> */}
    </>
  );
};

StakeNft.propTypes = {};

StakeNft.defaultProps = {};

export default StakeNft;
