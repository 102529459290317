import React, { FunctionComponent, useEffect, useState } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { Drawer, Popover } from "antd";
import {
  closeBlueBtn,
  connectWalletBtn,
  menuIcon,
  mintNowBtn,
  mobileWalletBtn,
  startBtn,
  townhallLogo,
} from "helper/constants";
// import CountdownTimer from "./CountdownTimer";

const TopHeaderWrapper = styled.div`
  a {
    color: white;
  }
  .close_btn_div {
  }
  .fix-header {
    background: #17173e;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    z-index: 9;
    justify-content: center;
    max-width: 100%;
    padding-top: 20px !important;
    padding-bottom: 20px !important;

  }
  .owner-logo {
    max-height: 45px !important;
  }
  .overlay {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background: rgba(23, 23, 62, 0.54);
  }
  @media (max-width: 1100px) {
    .app-container {
      justify-content: space-between;
      padding-left: 10px;
      padding-right: 10px;
    }
    .navigation-btns {
      display: none;
    }
    .count-timer {
      padding-left: 76px;

      @media (max-width: 1100px) {
        padding-left: 0;
      }
    }
    @media (max-width: 575px) {
      .app-container {
        padding: 30px 25px 0 22px !important;
      }
      .fix-header {
        padding: 13px 22px !important;
      }
      .count-timer {
        display: none;
      }
      .cwbtn {
        display: none;
      }
    }
    @media (max-width: 500px) {
      .owner-logo {
        width: 120px;
      }
    }
    .app-container .wallet-btn {
      width: 200px;
      padding: 0;

      @media (max-width: 500px) {
        width: 150px;
      }
    }
  }

  @media (min-width: 700px) {
    .mobile_btn {
      display: none !important;
    }
  }
`;

interface TopHeaderProps {
  countTimer?: boolean;
  setVisible?: any;
  visible?: any;
  content?: any;
  showModal?: any;
  isBattleground?: boolean
}

const TopHeader: FunctionComponent<TopHeaderProps> = ({
  countTimer = true,
  setVisible,
  visible,
  content,
  showModal,
  isBattleground = false
}) => {
  const [visibleNew, setVisibleNew] = useState(false);
  const [scrollHeader, setScrollHeader] = useState(false);
  const navigate = useNavigate();

  const showDrawer = () => {
    setVisibleNew(true);
  };

  const onClose = () => {
    setVisibleNew(false);
  };

  const handleVisibleChange = (newVisible: boolean) => {
    setVisible(newVisible);
  };

  useEffect(() => {
    const handleScroll = () => {
      setScrollHeader(window.scrollY > 0);
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <TopHeaderWrapper>
      <div
        className={`pt45 flex items-center justify-center app-container mb_position ${scrollHeader ? "fix-header" : ""
          }`}
      >
        {isBattleground === false ? (
          <div className="flex navigation-btns">
            <img
              src={townhallLogo}
              alt="townhallLogo"
              className="owner-logo cursor-pointer"
              onClick={() => navigate("/")}
            />
            <a href="#about">
              <div className="an-24 vt-regular-text pl55">About</div>
            </a>
            <a href="#survivaljourney">
              <div className="an-24 vt-regular-text px30">Survival Journey</div>
            </a>
            <a href="#gamestep">
              <div className="an-24 vt-regular-text ">Game steps</div>
            </a>
            {/* <a href="#tokenomics">
            <div className="an-24 vt-regular-text">Tokenomics</div>
          </a> */}
            {/* <a href="#leaderboard">
            <div className="an-24 vt-regular-text px30">Leaderboard</div>
          </a> */}
            <a href="#faq">
              <div className="an-24 vt-regular-text cursor-pointer px30">FAQ</div>
            </a>
          </div>
        ) :
          (
            <div className="an-58 b-bold-text welcome-title text-center">BATTLEGROUND</div>
          )}

        {/*countTimer ? (
          <div className="count-timer pl76">
          {/*  <div className="an-20 vt-regular-text lightgray--text">
              Portal time:
            </div>
            <div className="an-16 b-bold-text mt5">17h : 24m : 56s</div> */}
        {/* <CountdownTimer fontsize="16px" width="130px" /> */}
        {/* </div> */}
        {/* ) : ( */}
        {/* <div></div> */}
        {/* ) */}
        <Popover
          placement="bottomRight"
          content={content}
          trigger="click"
          visible={visible}
          onVisibleChange={handleVisibleChange}
          overlayClassName="connect-wallet-popup"
        >
          {visible && <div className="overlay"></div>}
          {/*  <img
            src={connectWalletBtn}
            alt="connectWalletBtn"
            className="pl26 cursor-pointer wallet-btn cwbtn"
            onClick={() => {
              setVisible(true);
            }}
          /> */}
          {/* uncomment */}
          {/* <img
            src={startBtn}
            alt="startBtn"
            className="cursor-pointer mobile_btn"
            onClick={() => {
              setVisible(true);
            }}
          /> */}
        </Popover>
        <img
          src={menuIcon}
          alt="menuIcon"
          className="cursor-pointer mobile_btn"
          onClick={showDrawer}
        />
        <Drawer
          placement="right"
          visible={visibleNew}
          height="auto"
          width="100%"
          className="menu-drawer"
        >
          <div className="flex pb54 pt25">
            <img
              src={townhallLogo}
              alt="townhallLogo"
              className="flex margin-auto logo"
            />
          </div>
          <img
            src={closeBlueBtn}
            alt="closeBlueBtn"
            onClick={onClose}
            className="pr20 close_btn_div "
            style={{ position: "absolute", right: "12px", top: "12px" }}
          />
          {/* <div className="flex justify-content align-center flex-col text-center pb40">
            <div className="an-20 vt-regular-text lightgray--text">
              Portal time:
            </div>
            <div className="an-16 b-bold-text mt5">17h : 24m : 56s</div>
          </div> */}
          <div className="flex justify-content align-center flex-col text-center">
            <a href="#about">
              <div
                className="an-24 vt-regular-text menu_data"
                onClick={onClose}
              >
                About
              </div>
            </a>
            <a href="#gamestep">
              <div
                className="an-24 vt-regular-text menu_data"
                onClick={onClose}
              >
                Game steps
              </div>
            </a>
            {/* <a href="#tokenomics">
              <div
                className="an-24 vt-regular-text menu_data"
                onClick={onClose}
              >
                Tokenomics
              </div>
            </a>*/}
            <a href="#survivaljourney">
              <div
                className="an-24 vt-regular-text menu_data"
                onClick={onClose}
              >
                Survival Journey
              </div>
            </a> 
            <a href="faq">
              <div
                className="an-24 vt-regular-text menu_data"
                onClick={onClose}
              >
                FAQ
              </div>
            </a>
          </div>
          <div>
            {/* <div className="text-center py15">
              <img
                src={mobileWalletBtn}
                alt="connectWalletBtn"
                onClick={() => {
                  setVisible(true);
                }}
              />
            </div> */}
            {/* <div className="text-center">
              <img src={mintNowBtn} alt="mintNowBtn" onClick={showModal} />
            </div> */}
          </div>
        </Drawer>
      </div>
      {scrollHeader && <div style={{ height: "65px" }}></div>}
    </TopHeaderWrapper>
  );
};

TopHeader.propTypes = {};

TopHeader.defaultProps = {};

export default TopHeader;
