import React, { FunctionComponent, useEffect } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { Dropdown, Menu } from "antd";
import useDevice from "hooks/useDevice";
import {
  humansFlag,
  menuBtn,
  townhallLogo,
  discordBlueBtn,
  twitterBlueBtn,
  openSeaBlueBtn,
  logoutYellowBtn,
  humans,
  orcsFlag,
  evlesFlag,
} from "helper/constants";
import { getClanValue } from "redux/selectors/demo";
import { IClanValue } from "interface/demo";

const HeaderWrapper = styled.div`
  @media (max-width: 575px) {
    .grid {
      display: grid;
      grid-template-columns: 1fr 1fr;
    }
    .time-section {
      grid-row: 2/3;
    }
  }
`;

const menu = (
  <Menu
    className="menu-dropdown"
    items={[
      {
        key: "0",
        label: (
          <div>
            <div className="an-20 vt-regular-text lightgray--text ">
              Wallet:
            </div>
            <div className="an-16 b-bold-text">0x93....a32F9A</div>
          </div>
        ),
      },
      {
        key: "1",
        label: (
          <div className="flex items-center">
            <img src={discordBlueBtn} alt="discordBlueBtn" />
            <div className="an-16 b-bold-text pl15">DISCORD</div>
          </div>
        ),
      },
      {
        key: "2",
        label: (
          <div className="flex items-center">
            <a href="https://twitter.com/WarlordsOnETH">
              <img src={twitterBlueBtn} alt="twitterBlueBtn" />
            </a>
            <div className="an-16 b-bold-text pl15">TWITTER</div>
          </div>
        ),
      },
      {
        key: "3",
        label: (
          <div className="flex items-center">
            <img src={openSeaBlueBtn} alt="openSeaBlueBtn" />
            <div className="an-16 b-bold-text pl15">OPEN SEA</div>
          </div>
        ),
      },
      {
        key: "4",
        label: (
          <div className="flex items-center">
            <img src={logoutYellowBtn} alt="logoutYellowBtn" />
            <div className="an-16 b-bold-text pl15">LOGOUT</div>
          </div>
        ),
      },
    ]}
  />
);

interface HeaderProps { }

const Header: FunctionComponent<HeaderProps> = () => {
  const navigate = useNavigate();
  const { isMobile } = useDevice();
  const clanValue: IClanValue | null = useSelector(getClanValue);
  const [clanName, setClanName] = React.useState(clanValue?.title);
  const [flagImage, setFlagImage] = React.useState(humansFlag);
  const [flagTag, setFlagTag] = React.useState("humansFlag" );

    const [timeLeft, setTimeLeft] = React.useState({
      hours: 0,
      minutes: 0,
      seconds: 0,
    });
  
    // Set your target time here (e.g., 17 hours from now)
    // Memoize the target time to ensure it doesn't change on every render
    const targetTime = React.useMemo(() => {
      const now = new Date();
      // Set the hours to 23 (11 PM) and minutes, seconds, and milliseconds to 0 for UTC
      now.setUTCHours(23, 0, 0, 0);
      return now;
    }, []); // Empty dependency array ensures this runs only once  
  
    useEffect(() => {
      const interval = setInterval(() => {
        const now = new Date();
        const difference = targetTime.getTime() - now.getTime();
  
        if (difference > 0) {
          const hours = Math.floor((difference / (1000 * 60 * 60)) % 24);
          const minutes = Math.floor((difference / (1000 * 60)) % 60);
          const seconds = Math.floor((difference / 1000) % 60);
  
          setTimeLeft({ hours, minutes, seconds });
        } else {
          clearInterval(interval); // Stop countdown when time is up
        }
      }, 1000);
  
      return () => clearInterval(interval); // Cleanup on component 
    }, [targetTime]);

  useEffect(() => {
    if (clanValue && clanValue.title === "ELVES") {
      setFlagImage(evlesFlag);
      setFlagTag("elvesFlag");
      setClanName("Elves");
    } else if (clanValue && clanValue.title === "ORCS") {
      setFlagImage(orcsFlag);
      setFlagTag("orcsFlag");
    };
  }, [clanValue]);


  return (
    <HeaderWrapper>
      <div
        className={`${isMobile ? "justify-center" : "justify-between"
          } pt40 flex items-center `}
      >
        <img
          src={townhallLogo}
          alt="townhallLogo"
          className={isMobile ? "hidden" : "cursor-pointer"}
          onClick={() => navigate("/")}
        />
        <div className="flex justify-between grid">
          <div className={`${isMobile ? "an-16" : "an-28"} b-bold-text`}>
            <img
              src={flagImage}
              alt={flagTag}
              className={isMobile ? "pr11" : "pr20"}
              height={isMobile ? 32 : 48}
            />
            {clanName} Clan
          </div>
          <div
            className={`${isMobile ? "px0 pt16" : "px80 "
              } count-timer time-section`}
          >
            <div
              className={`${isMobile ? "an-14" : "an-20"
                } vt-regular-text lightgray--text`}
            >
              Next Battle Time:
            </div>
            <div className={`${isMobile ? "an-16" : "an-20"} b-bold-text mt10`}>
              {timeLeft.hours === 0 && timeLeft.minutes === 0 && timeLeft.seconds == 0 ? (
                "--h : --m : --s"
              ) : (
                `${timeLeft.hours.toString().padStart(2, "0")}h : ${timeLeft.minutes
                  .toString()
                  .padStart(2, "0")}m : ${timeLeft.seconds.toString().padStart(2, "0")}s`
              )}
          </div>
          </div>

          <Dropdown overlay={menu} trigger={["click"]} placement="bottomRight">
            <div className={isMobile ? "flex justify-end" : ""}>
              <img
                src={menuBtn}
                alt="menuBtn"
                className="cursor-pointer"
                height={isMobile ? 34 : ""}
                width={isMobile ? 98 : ""}
              />
            </div>
          </Dropdown>
        </div>
      </div>
    </HeaderWrapper>
  );
};

Header.propTypes = {};

Header.defaultProps = {};

export default Header;
