import React, { FunctionComponent } from "react";
import { Routes, Route } from 'react-router-dom';
import MainLayout from 'containers/MainLayout'
import ChooseClan from "containers/chooseClan";
import StakeSuccessfull from "components/stake/StakeSuccessfull";
import GameScreen from "containers/gameScreen";
import Tokenomics from "containers/tokenomics";
import Minting from "containers/minting";
import Battleground from "containers/battleground";
import Leaderboard from "containers/leaderboard";

interface AppRoutesProps {}

const AppRoutes: FunctionComponent<AppRoutesProps> = () => {
  return (
    <Routes>
      <Route path="/" element={<MainLayout />} />
      <Route path="/battleground" element={<Battleground />} />
      <Route path="/leaderboard" element={<Leaderboard />} />
      <Route path="/chooseClan" element={<ChooseClan />} />
      <Route path="/stakeSuccessfull" element={<StakeSuccessfull />} />
      <Route path="/gameScreen" element={<GameScreen />} /> 
      {/* <Route path="/tokenomics" element={<Tokenomics />} /> */}
      <Route path="/minting" element={<Minting />} />
    </Routes>
  );
};

export default AppRoutes;
