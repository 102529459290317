import React, { FunctionComponent, useEffect, useState } from "react";
import styled from "styled-components";
import { Slider } from "antd";
import useDevice from "hooks/useDevice";
import {
  activeBorder,
  borderImg,
  coinIcon,
  allocateMobileActiveBorder,
  allocateMobileBorder,
} from "helper/constants";

const AllocateResourcesWrapper = styled.div`
  .card {
    width: 360px;
    height: 456px;
  }
  .active_border {
    position: absolute;
    top: -14px;
    z-index: 2;
    bottom: 0;
    right: 0;
    left: 0;
  }
  .background__img {
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: 1;
  }
  .card_icon {
    top: 37px;
    left: 27px;
    right: 0;
    bottom: 0;
    z-index: 2;
    clip-path: polygon(50% 99%,99% 73%,99% 0,0 0,0 73%);
    width: 308px;
    height: 327px;
  }
  .card_title {
    z-index: 2;
    bottom: 30px;
    width: 100%;
    right: 5px;
  }
  @media (max-width: 575px) {
    .mobile_card {
      max-width: 330px;
      margin: 0 auto;
      margin-bottom: 8px !important;
    }
    .card_icon {
      width: 48px;
      height: 48px;
      z-index: 111;
      top: 10px !important;
      left: 37px !important;
    }
    .mobile_title {
      position: absolute;
      z-index: 1111;
      top: 17px;
      left: 10px;
      right: 0;
    }
    .content {
      width: 330px !important;
    }
  }
`;

interface AllocateResourcesProps {
  backgroundImg: any;
  cardIcon: any;
  title: any;
  handleClick: Function;
  index: number;
  active: number | null;
  percentage: any;
  stakedValue: number,
  sliderBtnColor: any;
  bgColor?: any;
  marginTop: any;
  stakeChanged: Function
}

const AllocateResources: FunctionComponent<AllocateResourcesProps> = ({
  backgroundImg,
  cardIcon,
  title,
  handleClick,
  index,
  active,
  percentage,
  stakedValue,
  sliderBtnColor,
  bgColor,
  marginTop,
  stakeChanged
}) => {

  const { isMobile } = useDevice();
  const [currentPercentage, setCurrentPercentage] = useState(percentage);
  const lim = 11800;
  const handleChange = (value: number) => {
    const per = Math.floor(value * 100.0/lim);

    setCurrentPercentage(value.toString() + "-" + per.toString());

    stakeChanged(value, title, index);
    // Add your logic here based on the new value
  };

  if (!isMobile) {
    return (
      <AllocateResourcesWrapper>
        <>
          <div
            className="card relative"
            onClick={() => handleClick && handleClick(index)}
          >
            <img
              src={active === index ? activeBorder : borderImg}
              alt="activeBorder"
              className="active_border"
            />
            <img
              src={backgroundImg}
              alt="backgroundImg"
              className="absolute background__img"
            />
            <img src={cardIcon} alt="cardIcon" className="absolute card_icon" />
            <div className="an-36 b-bold-text text-center card_title absolute">
              {title}
            </div>
          </div>

          <div className="content pt26 pb5">
            <div className="flex justify-between">
              <div className="an-16 b-bold-text">{title}</div>
              <div className="flex">
                <img src={coinIcon} alt="coinIcon" className="pr6" />
                <div className="an-16 b-bold-text pt5">{currentPercentage}%</div>
              </div>
            </div>
            <Slider
              defaultValue={stakedValue}
              min={0}
              max={lim}
              step={100}
              onChange={handleChange}
              trackStyle={{
                backgroundColor: bgColor,
                border: "3px solid",
                borderRadius: "0px",
              }}
              handleStyle={{
                marginTop: marginTop,
                backgroundSize: "auto",
                border: "none",
                borderRadius: "0px",
                background: `url(${sliderBtnColor})`,
                width: "73px",
                height: "73px",
              }}
              className={`slider-${index}`}
            />
          </div>
        </>
      </AllocateResourcesWrapper>
    );
  }
  return (
    <AllocateResourcesWrapper>
      <div
        className="relative mobile_card"
        onClick={() => handleClick && handleClick(index)}
      >
        <img
          src={
            active === index ? allocateMobileActiveBorder : allocateMobileBorder
          }
          alt="allocateMobileActiveBorder"
        />
        <img src={cardIcon} alt="cardIcon" className="absolute card_icon" />
        <div className="an-20 b-bold-text text-center mobile_title">
          {title}
        </div>

        <div className="content pt22 pb5">
          <div className="flex justify-between">
            <div className="an-16 b-bold-text">{title}</div>
            <div className="flex">
              <img src={coinIcon} alt="coinIcon" className="pr6" />
              <div className="an-16 b-bold-text pt5">{currentPercentage}%</div>
            </div>
          </div>
          <Slider
            defaultValue={stakedValue}
            min={0}
            max={lim}
            onChange={handleChange}
            trackStyle={{
              backgroundColor: bgColor,
              border: "3px solid",
              borderRadius: "0px",
            }}
            handleStyle={{
              marginTop: marginTop,
              backgroundSize: "auto",
              border: "none",
              borderRadius: "0px",
              background: `url(${sliderBtnColor})`,
              width: "73px",
              height: "73px",
            }}
            className={`slider-${index}`}
          />
        </div>
      </div>
    </AllocateResourcesWrapper>
  );
};

AllocateResources.propTypes = {};

AllocateResources.defaultProps = {};

export default AllocateResources;
