import React, { FunctionComponent, useState } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import useDevice from "hooks/useDevice";
import { message } from "antd";
import {
  closeBtn,
  spaceBackground,
  colorBackground,
  humansIcon,
  orcsIcon,
  evlesIcon,
  continueBtn,
  humansFlag,
  orcsFlag,
  evlesFlag,
} from "helper/constants";
import ChooseCard from "components/common/ChooseCard";
import { getClanValue } from "redux/actions/demo";
import { getNFTValues } from "redux/selectors/demo";
import { INFTValue } from "interface/demo";

const clanConstant = [
  {
    id: 1,
    cardIcon: humansIcon,
    flagImg: humansFlag,
    title: "HUMANS",
  },
  {
    id: 2,
    cardIcon: orcsIcon,
    flagImg: orcsFlag,
    title: "ORCS",
  },
  {
    id: 3,
    cardIcon: evlesIcon,
    flagImg: evlesFlag,
    title: "ELVES",
  },
];

const ChooseClanWrapper = styled.div`
  .clan-page {
    background: url(${spaceBackground});
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    height: 100vh;
  }
  .choose_details {
    max-width: 478px;
  }
  .close_btn {
    position: absolute;
    top: 59px;
    right: 0;
  }
  @media (max-width: 575px) {
    .mobile-container {
      max-width: 500px;
    }
    .choose_details {
      font-size: 20px !important;
      width: 256px;
      line-height: 100%;
      padding-top: 10px !important;
      padding-bottom: 23px !important;
    }
    .close_btn {
      position: absolute;
      top: 30px;
      right: 30px;
    }
  }
`;

interface ChooseClanProps {}

const ChooseClan: FunctionComponent<ChooseClanProps> = () => {
  const navigate = useNavigate();
  const { isMobile } = useDevice();
  const dispatch = useDispatch();
  const [active, setActive] = useState<any>(null);

  const nftValues: INFTValue[] | null = useSelector(getNFTValues);

  const handleClick = (data: any) => {
    if (active === data?.id) {
      setActive(null);
    } else {
      setActive(data);
      dispatch(getClanValue(data));
    }
  };

  const continueHandler = () => {
    if (active) {
      navigate("/gameScreen");
    } else {
      message.error("Please choose any clan card");
    }
  };

  return (
    <ChooseClanWrapper>
      <div className="clan-page">
        <div className="app-container mobile-container">
          <div
            className={`${
              isMobile ? "an-28 pt90" : "an-48 pt53"
            } b-bold-text text-center relative`}
          >
            <div>Choose your Clan</div>
            <img
              src={closeBtn}
              alt="closeBtn"
              className="close_btn cursor-pointer"
              onClick={() => navigate("/")}
            />
          </div>
          <div className="an-24 vt-regular-text lightgray--text pt17 text-center margin-auto choose_details">
            Pick your clan and get ready for battle<br></br>   
          </div>
          <div className={!isMobile ? "app-container flex" : "app-container"}>
            {clanConstant.map((data, index) => (
              <div className={index > 0 && !isMobile ? "ml40" : ""} key={index}>
                <ChooseCard
                  backgroundImg={colorBackground}
                  cardIcon={data.cardIcon}
                  flagImg={data.flagImg}
                  title={data.title}
                  handleClick={() => handleClick(data)}
                  index={index + 1}
                  active={active?.id}
                />
              </div>
            ))}
          </div>
          <img
            src={continueBtn}
            alt="continueBtn"
            className={`${
              isMobile ? "pt25" : "pt50"
            } pb20 flex margin-auto cursor-pointer`}
            onClick={continueHandler}
          />
        </div>
      </div>
    </ChooseClanWrapper>
  );
};

ChooseClan.propTypes = {};

ChooseClan.defaultProps = {};

export default ChooseClan;
