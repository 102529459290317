import React, { FunctionComponent, useEffect, useState } from "react";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { closeBtn, connectWalletBtn, metamaskIcon } from "helper/constants";
import { getTodos } from "redux/actions/demo";
import TopSection from "components/mainLayout/TopSection";
import Footer from "components/common/Footer";
import AboutOurWorld from "components/mainLayout/AboutOurWorld";
import GameStepFirst from "components/mainLayout/GameSteps/First";
import GameStepSecond from "components/mainLayout/GameSteps/Second";
import GameStepThird from "components/mainLayout/GameSteps/Third";
import GameStepFourth from "components/mainLayout/GameSteps/Fourth";
import TokenomicsExploration from "components/mainLayout/TokenomicsExploration";
import Leaders from "components/mainLayout/Leaders";
import AddressLink from "components/mainLayout/AddressLink";
import StartPlaying from "components/mainLayout/StartPlaying";
import Faq from "components/mainLayout/Faq";

const MainLayoutWrapper = styled.div``;

interface MainLayoutProps {}

const MainLayout: FunctionComponent<MainLayoutProps> = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [visible, setVisible] = useState(false);

  const showModal = () => {
    setVisible(true);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const content = (
    <div className="white--text">
      <div className="flex items-center justify-between">
        <div className="an-24 b-bold-text">Connect Wallet</div>
        <img
          src={closeBtn}
          alt="closeBtn"
          className="cursor-pointer"
          onClick={handleCancel}
        />
      </div>
      <div className="an-24 vt-regular-text lightgray--text mt5 mobile_desc">
        Choose methods to connect your wallet
      </div>
      <img
        src={metamaskIcon}
        alt="metamaskIcon"
        className="py26 flex margin-auto"
      />
      <img
        src={connectWalletBtn}
        alt="connectWalletBtn"
        className="cursor-pointer wallet-btn flex margin-auto"
        onClick={() => navigate("/chooseClan")}
      />
    </div>
  );

  useEffect(() => {
    // dispatch(getTodos());
  }, [dispatch]);

  return (
    <MainLayoutWrapper>
      <TopSection
        setVisible={setVisible}
        visible={visible}
        content={content}
        showModal={showModal}
      />
      <AboutOurWorld />
      <GameStepFirst />
      <GameStepSecond />
      <GameStepThird />
      <GameStepFourth />
      <TokenomicsExploration />
      <AddressLink />
      {/* <Leaders /> */}
      <Faq />
      <StartPlaying showModal={showModal} />
      <Footer />
    </MainLayoutWrapper>
  );
};

MainLayout.propTypes = {};

MainLayout.defaultProps = {};

export default MainLayout;
