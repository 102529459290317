import React, { FunctionComponent } from "react";
import styled from "styled-components";
import useDevice from "hooks/useDevice";
import { closeBtn, coinIcon, continueBtn } from "helper/constants";

const StakeSuccessfullWrapper = styled.div`
  .success_close {
    margin-top: -26px;
    margin-right: 252px;
  }
  .center_position {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  @media (max-width: 575px) {
    margin-top: -50px;
    .success_close {
      margin-right: 20px;
      position: absolute;
      right: 0;
      top: 0;
    }
    .success_title {
      font-size: 28px !important;
      width: 296px;
      margin: 0 auto;
      margin-top: 40px !important;
    }
  }
`;

interface StakeSuccessfullProps {
  successClose?: any;
}

const StakeSuccessfull: FunctionComponent<StakeSuccessfullProps> = ({
  successClose,
}) => {
  const { isMobile } = useDevice();

  return (
    <StakeSuccessfullWrapper>
      <div className="text-right success_close cursor-pointer">
        <img src={closeBtn} alt="closeBtn" onClick={successClose} />
      </div>
      <div className="center_position">
        <img
          src={coinIcon}
          alt="coinIcon"
          className="flex margin-auto"
          width={isMobile ? "95px" : "149px"}
          height={isMobile ? "95px" : "149px"}
        />
        <div className="an-48 b-bold-text mt100 text-center success_title">
          Staking successfull
        </div>
        <div
          className={`${
            isMobile ? "an-20" : "an-24"
          }  vt-regular-text lightgray--text pt5 text-center`}
        >
          You have more resources to play
        </div>
        <img
          src={continueBtn}
          alt="continueBtn"
          className="pt40 pb20 flex margin-auto cursor-pointer"
          onClick={successClose}
        />
      </div>
    </StakeSuccessfullWrapper>
  );
};

StakeSuccessfull.propTypes = {};

StakeSuccessfull.defaultProps = {};

export default StakeSuccessfull;
