import React, { FunctionComponent } from "react";
import styled from "styled-components";
import {
  playingLine,
  startPlayingBtn,
  mobiilePlayingLine,
} from "helper/constants";

const StartPlayingWrapper = styled.div`
  .choose-clan {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    margin-top: 100px;
  }
  .cyc-title {
    position: absolute;
    width: 820px;
    text-align: center;
    bottom: -32px;
  }
  .play-btn {
    top: 40px;
  }
  .mobile-img {
    display: none;
  }
  @media (max-width: 575px) {
    .cyc-title {
      font-size: 28px !important;
      max-width: 294px;
    }
    .web-img {
      display: none;
    }
    .mobile-img {
      display: block;
    }
  }
`;

interface StartPlayingProps {
  showModal?: any;
  titleText?: string;
}

const StartPlaying: FunctionComponent<StartPlayingProps> = ({ 
  showModal,
  titleText="Help your nation on the battle ground!"
  }) => {
  const handleOpen = () => {
    showModal();
    window.scrollTo(0, 0);
  };

  return (
    <StartPlayingWrapper>
      <div className="choose-clan">
        <img
          src={mobiilePlayingLine}
          alt="mobiilePlayingLine"
          className="absolute mobile-img"
        />
        <img src={playingLine} alt="playingLine" className="absolute web-img" />
        <div className="an-48 b-bold-text cyc-title">
          {titleText}
        </div>
        <img
          src={startPlayingBtn}
          alt="startPlayingBtn"
          className="absolute play-btn cursor-pointer"
          // onClick={handleOpen}
        />
      </div>
    </StartPlayingWrapper>
  );
};

StartPlaying.propTypes = {};

StartPlaying.defaultProps = {};

export default StartPlaying;
