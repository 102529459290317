import React, { FunctionComponent, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import useDevice from "hooks/useDevice";
import {
  closeBtn,
  colorBackground,
  orcsIcon,
  evlesIcon,
  humansDefense,
  orcsDefense,
  elvesDefense,
  researchCardIcon,
  useRainBtn,
  redSliderBtn,
  greenSliderBtn,
  yellowSliderBtn,
  humansIcon,
  lightBlueSliderBtn,
} from "../../helper/constants";
import AllocateResources from "components/gameScreen/AllocateResources";
import { getClanValue } from "redux/selectors/demo";
import { IClanValue } from "interface/demo";


const AllocateCoinModalWrapper = styled.div`
  margin-top: -35px;
  .clan_desc {
    max-width: 478px;
    margin-bottom: 40px !important;
  }
  .close_btn {
    position: absolute;
    top: 8px;
    right: -19px;
  }
  .content {
    width: 360px;
  }
  .flex_center{
    align-items: center;
    justify-content: center;
  }
  @media (max-width: 575px) {
    .mobile_heading {
      width: 296px;
      margin: 0 auto;
      font-size: 28px !important;
      padding-top: 20px !important;
    }
    .close_btn {
      top: -50px;
      right: -10px;
    }
    .clan_desc {
      max-width: 256px;
      font-size: 20px !important;
      padding-top: 10px !important;
      margin-bottom: 20px !important;
    }
  }
`;

interface AllocateCoinModalProps {
  allocateClose?: any;
  updateStaked: Function
}

const AllocateCoinModal: FunctionComponent<AllocateCoinModalProps> = ({
  allocateClose,
  updateStaked
}) => {
  const [active, setActive] = useState<number | null>(null);
  const { isMobile } = useDevice();

  const stakedValues = {
    attackOne: 0,
    attackTwo: 0,
    defense: 0
  };

  const stakedTitles = {
    attackOne: "",
    attackTwo: "",
    defense: ""
  };

  const handleClick = (index: number) => {
    if (active === index) {
      setActive(null);
    } else {
      setActive(index);
    }
  };

  const clanValue: IClanValue | null = useSelector(getClanValue);

  // Define a base constant for the second element
  const defenseElement = {
    cardIcon: humansDefense,
    title: "DEFENDERS",
    percentage: "0-0",
    stakedValue: 0,
    color: "lightblue",
    sliderBtnColor: lightBlueSliderBtn,
    marginTop: "-31px",
  };

  const attackOneElement = {
    cardIcon: orcsIcon,
    title: "ATTACK ORCS",
    percentage: "0-0",
    stakedValue: 0,
    color: "red",
    sliderBtnColor: redSliderBtn,
    marginTop: "-31px",
  };

  const attackTwoElement = {
    cardIcon: evlesIcon,
    title: "ATTACK ELVES",
    percentage: "0-0",
    stakedValue: 0,
    color: "green",
    sliderBtnColor: greenSliderBtn,
    marginTop: "-36px",
  };
  
  
  // Dynamically set the cardIcon based on clanValue?.title
  if (clanValue && clanValue.title === "ELVES") {
    defenseElement.cardIcon = elvesDefense;

    attackTwoElement.title = "ATTACK HUMANS";
    attackTwoElement.cardIcon = humansIcon;

    defenseElement.color = "green";
    attackOneElement.color = "red";
    attackTwoElement.color = "lightblue";

    defenseElement.sliderBtnColor = greenSliderBtn;
    attackOneElement.sliderBtnColor = redSliderBtn;
    attackTwoElement.sliderBtnColor = lightBlueSliderBtn;
  } else if (clanValue && clanValue.title === "ORCS") {
    defenseElement.cardIcon = orcsDefense;

    attackOneElement.title = "ATTACK HUMANS";
    attackOneElement.cardIcon = humansIcon;

    defenseElement.color = "red";
    attackOneElement.color = "lightblue";
    attackTwoElement.color = "green";

    defenseElement.sliderBtnColor = redSliderBtn;
    attackOneElement.sliderBtnColor = lightBlueSliderBtn;
    attackTwoElement.sliderBtnColor = greenSliderBtn;
  };
  
  // Construct the clanConstant array
  const clanConstant = [
    attackOneElement,
    defenseElement,
    attackTwoElement,
  ];

  const allocateProwess = function () {
    updateStaked(stakedValues, stakedTitles);
    allocateClose(true);
  };

  const stakeChanged = function (value: number, title: string, index: number) {
    if(index == 1){
      stakedValues.attackOne = value;
      stakedTitles.attackOne = title;
    }else if(index == 2){
      stakedValues.defense = value;
      stakedTitles.defense = title;
    }else{
      stakedValues.attackTwo = value;
      stakedTitles.attackTwo = title;
    }
  };

  const closeModal = function () {
      allocateClose(false);
  };

  const confirmModal = function () {
    allocateClose(true);
};

  return (
    <AllocateCoinModalWrapper>
      <div className="app-container">
        <div className="an-48 b-bold-text text-center relative mobile_heading">
          <div>Allocate your AQUIS coins</div>
          <img
            src={closeBtn}
            alt="closeBtn"
            className="close_btn cursor-pointer"
            onClick={closeModal}
          />
        </div>
        <div className="an-24 vt-regular-text lightgray--text pt5 text-center margin-auto clan_desc">
          Strategically allocate your battle prowess!
        </div>
        <div className={!isMobile ? "app-container flex flex_center" : "app-container"}>
          {clanConstant.map((data, index) => (
            <div className={index > 0 && !isMobile ? "ml40" : ""} key={index}>
              <AllocateResources
                backgroundImg={colorBackground}
                cardIcon={data.cardIcon}
                title={data.title}
                handleClick={handleClick}
                index={index + 1}
                active={active}
                percentage={data.percentage}
                stakedValue={data.stakedValue}
                sliderBtnColor={data.sliderBtnColor}
                bgColor={data.color}
                marginTop={data.marginTop}
                stakeChanged={stakeChanged}
              />
            </div>
          ))}
        </div>
        <img
          src={useRainBtn}
          alt="continueBtn"
          className={`${
            isMobile ? "pt20" : "pt45"
          } pb20 flex margin-auto cursor-pointer`}
          onClick={allocateProwess}
        />
      </div>
    </AllocateCoinModalWrapper>
  );
};

AllocateCoinModal.propTypes = {};

AllocateCoinModal.defaultProps = {};

export default AllocateCoinModal;
