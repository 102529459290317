import React, { FunctionComponent } from "react";
import styled from "styled-components";
import useDevice from "hooks/useDevice";
import {
  activeBorder,
  borderImg,
  mobileColorBackground,
  mobileNftBorder,
  mobileActiveBorder,
} from "helper/constants";

const ChooseCardWrapper = styled.div`
  .card {
    width: 360px;
    height: 456px;
  }
  .active_border {
    position: absolute;
    top: -14px;
    z-index: 2;
    bottom: 0;
    right: 0;
    left: 0;
  }
  .background__img {
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: 1;
  }
  .card_icon {
    top: 37px;
    left: 30px;
    right: 0;
    bottom: 0;
    z-index: 2;
    clip-path: polygon(50% 99%,99% 73%,99% 0,0 0,0 73%);
    width: 304px;
    height: 327px;
  }
  .card_title {
    z-index: 2;
    bottom: 30px;
    width: 100%;
    right: 5px;
  }
  @media (max-width: 575px) {
    .mobile_card {
      max-width: 330px;
      margin: 0 auto;
      margin-bottom: 8px !important;
    }
    .mobile_bg {
      z-index: 111;
      position: relative;
    }
    .mobile_color_bg {
      width: 312px;
      position: absolute;
      left: 9px;
    }
    .card_icon {
      width: 98px;
      height: 98px;
      top: 20px !important;
      left: 40px !important;
    }
    .mobile_card_title {
      display: flex;
      position: absolute;
      top: 45px;
      left: 150px;
    }
    .mobile_title {
      padding-top: 12px;
    }
  }
`;

interface ChooseCardProps {
  backgroundImg: any;
  cardIcon: any;
  title: any;
  flagImg: any;
  handleClick: Function;
  index: number;
  active: number | null;
}

const ChooseCard: FunctionComponent<ChooseCardProps> = ({
  backgroundImg,
  cardIcon,
  title,
  flagImg,
  handleClick,
  index,
  active,
}) => {
  const { isMobile } = useDevice();

  if (!isMobile) {
    return (
      <ChooseCardWrapper>
        <div
          className="card relative mt40"
          onClick={() => handleClick && handleClick(index)}
        >
          <img
            src={active === index ? activeBorder : borderImg}
            alt="activeBorder"
            className="active_border"
          />
          <img
            src={backgroundImg}
            alt="backgroundImg"
            className="absolute background__img"
          />
          <img src={cardIcon} alt="cardIcon" className="absolute card_icon" />
          <div className="card_title flex absolute items-center justify-center">
            <img src={flagImg} alt="flagImg" />
            <div className="an-24 b-bold-text text-center pl15">{title}</div>
          </div>
        </div>
      </ChooseCardWrapper>
    );
  }
  return (
    <ChooseCardWrapper>
      <div
        className="relative mobile_card"
        onClick={() => handleClick && handleClick(index)}
      >
        <img
          src={active === index ? mobileActiveBorder : mobileNftBorder}
          alt="nftBorder"
          className="mobile_bg"
        />
        <img
          src={mobileColorBackground}
          alt="colorBackground"
          className="mobile_color_bg"
        />
        <img src={cardIcon} alt="cardIcon" className="absolute card_icon" />
        <div className="mobile_card_title">
          <img src={flagImg} alt="flagImg" width={24} />
          <div className="an-18 b-bold-text text-center pl10 mobile_title">
            {title}
          </div>
        </div>
      </div>
    </ChooseCardWrapper>
  );
};

ChooseCard.propTypes = {};

ChooseCard.defaultProps = {};

export default ChooseCard;
