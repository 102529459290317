import React, { FunctionComponent, useState } from "react";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import {
  earthGif,
  elvesGif,
  startDemoBtn,
  // homeBackEllipse,
  lavaPlanetGif,
  homeBackground,
  mintNowBtn,
  elvesBackShadow,
  earthBackShadow,
  lavaBackShadow,
  mintNowHover,
} from "helper/constants";
import TopNavigation from "../common/TopNavigation";
import CountdownTimer from "components/common/CountdownTimer";
import { getNFTValues } from "redux/selectors/demo";
import { setNFTValues } from "redux/reducers/demo";
import { INFTValue } from "interface/demo";
import { useNavigate } from "react-router-dom";

const TopSectionWrapper = styled.div`
  .main-page {
    background: url(${homeBackground});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: bottom;
    width: 100%;
    height: 950px;
  }
  .welcome-title {
    padding-top: 100px !important;
    margin: 0 auto;
    max-width: 700px;
  }
  .townHall-description {
    max-width: 562px;
    margin: 0 auto;
  }
  .choose-clan {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    margin-top: 100px;
  }
  .name-token {
    margin-top: 0px !important;
  }
  .cyc-title {
    position: absolute;
    width: 820px;
    text-align: center;
    bottom: -32px;
  }
  .token-title {
    position: absolute;
    width: 606px;
    text-align: center;
    bottom: 53px;
  }
  .play-btn {
    top: 40px;
  }
  .coming_soon_hover {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  @media (max-width: 575px) {
    .main-page {
      height: 982px;
    }
    .welcome-title {
      font-size: 32px !important;
      margin-top: 310px !important;
      padding: 0 20px !important;
    }
    .townHall-description {
      font-size: 20px !important;
      padding: 25px 20px 0 !important;
    }
    .mn_btn {
      padding-top: 25px !important;
    }
    .timeshow {
      padding-top: 15px !important;
    }
  }
`;

const GifWrapper = styled.div`
  .lava_planet {
    right: -100px;
    width: 364px;
    height: 364px;
    top: 246px;
  }
  .lava_background_shadow {
    right: -130px;
    top: 116px;
    width: 623px;
    height: 623px;
  }
  .elves {
    width: 271px;
    height: 271px;
    top: 565px;
    left: 100px;
  }
  .elves_back_shadow {
    top: 480px;
    left: 20px;
  }
  .earth {
    width: 128px;
    height: 128px;
    top: 162px;
    left: 145px;
  }
  .earth_back_shadow {
    width: 268px;
    height: 268px;
    top: 92px;
    left: 75px;
  }
  @media (max-width: 575px) {
    .lava_planet {
      width: 185px;
      height: 185px;
      right: -20px;
      top: 121px;
    }
    .lava_background_shadow {
      width: 318px;
      height: 318px;
      top: 55px;
      right: -20px;
    }
    .elves {
      width: 121px;
      height: 121px;
      top: 240px;
      left: 50px;
    }
    .elves_back_shadow {
      width: 199px;
      height: 199px;
      top: 197px;
      left: 6px;
    }
    .earth {
      width: 80px;
      height: 80px;
      top: 115px;
      left: -10px;
    }
    .earth_back_shadow {
      width: 167px;
      height: 167px;
      top: 74px;
      left: -60px;
    }
  }
  /* .home_ellipse {
    top: 440px;
    left: 542px;
  } */
`;
interface TopSectionProps {
  setVisible: any;
  visible: any;
  content: any;
  showModal: any;
}

const TopSection: FunctionComponent<TopSectionProps> = ({
  setVisible,
  visible,
  content,
  showModal,
}) => {
  interface JsonItem {
    ID: number;
    Clan: string;
    Stake: number;
    Image: string;
    Active: boolean;
  }
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [hoverImg, setHoverImg] = useState(false);
  const nftValueList: INFTValue[] | null = useSelector(getNFTValues);

  const handleOpen = async () => {
    // showModal();
    window.scrollTo(0, 0);

    const dataArray: JsonItem[] = require('../../helper/NFTSampleData.json');

    // Transform the data into an array of INFTValue objects
    const nftValues = dataArray.map((item:JsonItem) => ({
      id: item.ID,
      clan: item.Clan,
      stake: item.Stake,
      url: item.Image,
      active: item.Active,
    }));

    // console.log(nftValueList);

    await dispatch(setNFTValues(nftValues));

    // console.log(nftValueList);

    navigate("/chooseClan");
    // Connect Wallet and read data for the wallet
  };

  return (
    <div className="relative overflow-hide">
      <GifWrapper>
        <div>
          <img
            src={lavaBackShadow}
            alt="lavaBackShadow"
            className="absolute lava_background_shadow"
          />
          <img
            src={lavaPlanetGif}
            alt="lavaPlanetGif"
            className="absolute lava_planet"
          />
        </div>
        <div>
          <img
            src={elvesBackShadow}
            alt="elvesBackShadow"
            className="absolute elves_back_shadow"
          />
          <img src={elvesGif} alt="elvesGif" className="absolute elves" />
        </div>
        <div>
          <img
            src={earthBackShadow}
            alt="earthBackShadow"
            className="absolute earth_back_shadow"
          />
          <img src={earthGif} alt="earthGif" className="absolute earth" />
        </div>
        {/* <img src={homeBackEllipse} alt="homeBackEllipse" className="absolute home_ellipse" /> */}
      </GifWrapper>
      <TopSectionWrapper>
        <div className="main-page">
          <TopNavigation
            setVisible={setVisible}
            visible={visible}
            content={content}
            showModal={showModal}
          />
          <div className="an-58 b-bold-text welcome-title text-center">
            Welcome to Warlords
          </div>
          <div className="an-24 vt-regular-text lightgray--text pt25 townHall-description text-center relative z-index1">
          Embark on an epic quest to save your people in this action-packed adventure! A rare celestial event has rocked the once-idyllic planet of Zenthia, and now the great Human, Orc, and Elf nations must fight for survival. 
          Choose your side and lead your nation to victory! Will you fortify your defenses or launch daring attacks to conquer your enemies? 
          Gather your resources, rally your troops, and get ready for a thrilling ride. Outwit your opponents and prove that your nation is the strongest in all of Zenthia! 
          Are you ready to rise to the challenge and save your people from destruction? The adventure awaits – let the battle for survival begin!          </div>
          <div className="relative">
            <img
              src={'/assets/mintsoon.png'}
              alt="mintSoonBtn"
              className="pt45 flex margin-auto cursor-pointer relative z-index1 mn_btn"
              onMouseOver={() => setHoverImg(true)}
              onMouseLeave={() => setHoverImg(false)}
              // onClick={handleOpen}
            />
            {hoverImg && (
              <img
                src={mintNowHover}
                alt="comingSoonHvr"
                className="coming_soon_hover"
              />
            )}
          </div>
           <div className="text-center pt30 timeshow relative">
            <div className="an-24 vt-regular-text lightgray--text">
              Time to Mint:
            </div>
            <div className="an-28 b-bold-text mt10">TBD</div>
            {/* <CountdownTimer fontsize="28px" /> */}
          </div>
        </div>
      </TopSectionWrapper>
    </div>
  );
};

TopSection.propTypes = {};

TopSection.defaultProps = {};

export default TopSection;
