import { AppDispatch } from "./../store";
import { setTodos, setLoading, setLeaders, setClanValue, setNFTValues, setClanStakeValues } from "redux/reducers/demo";
import DemoApi from "services/demo.service";

export const getTodos: any = () => async (dispatch: AppDispatch) => {
  const demoAPI = new DemoApi();
  dispatch(setLoading(true));

  const response = await demoAPI.getTodoList();
  if (response.status === 200) {
    dispatch(setTodos(response.data));
    dispatch(setLoading(false));
  }
};

export const getLeadersData: any = (tableData: any) => async (dispatch: AppDispatch) => {
  // const demoAPI = new DemoApi();
  dispatch(setLoading(true));
  if (tableData) {
    dispatch(setLeaders(tableData))
  }
  // const response = await demoAPI.getTodoList();
  // if (response.status === 200) {
  //   dispatch(setLeaders(response.data));
  //   dispatch(setLoading(false));
  // }
};

export const getClanValue: any = (data: any) => async (dispatch: AppDispatch) => {
  dispatch(setLoading(true));
  dispatch(setClanValue(data))
};

export const getNFTValues: any = (data: any) => async (dispatch: AppDispatch) => {
  dispatch(setLoading(true));
  dispatch(setNFTValues(data))
};

export const getClanStakeValues: any = (data: any) => async (dispatch: AppDispatch) => {
  dispatch(setLoading(true));
  dispatch(setClanStakeValues(data))
};