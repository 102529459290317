import React, { FunctionComponent } from "react";
import styled from "styled-components";
import { clanPlots } from "helper/constants";

const ThirdWrapper = styled.div`
  padding-top: 125px;
  @media (max-width: 900px) {
    padding-top: 62px;
    padding-left: 20px;
    padding-right: 20px;
  }

  /* @media (max-width: 1000px) {
    padding-left: 30px;
    padding-right: 30px;
  } */

  .nfd-detail {
    @media (max-width: 1000px) {
      padding-top: 62px;
    }
  }
  .contet-wrapper {
    display: grid;
    grid-template-columns: 68% 28%;
    gap: 4%;

    @media (max-width: 1000px) {
      grid-template-columns: 100%;
      gap: 0%;
    }
  }

  .game-img-container {
    display: grid;
    grid-template-columns: 100%;
    gap: 1.5%;
  }
  .game-img-container img {
    width: 100%;
  }
  @media (max-width: 575px) {
    .nfd-detail {
      padding-top: 50px;
    }
    .game_title {
      font-size: 28px !important;
      text-align: center;
    }
    .game_desc {
      margin-top: 20px !important;
      font-size: 20px !important;
      text-align: center;
    }
  }
`;

interface ThirdProps {}

const Third: FunctionComponent<ThirdProps> = () => {
  return (
    <ThirdWrapper className="app-container">
      <div className="contet-wrapper">
        <div className="game-img-container">
          <img src={clanPlots} alt="clan_plots" />
        </div>
        <div className="nfd-detail">
          <div className="an-36 b-bold-text game_title">
            3. Prepare for Battle
          </div>
          <div className="an-24 vt-regular-text lightgray--text mt30 game_desc">
          {/* Allocate AQUIS to strategically attack other nations to pillage their lands and gain more AQUIS during battle (battles occur every 24 hours). Outsmart enemies, and unlock powerful defenses and attacks with AQUIS. Level up and secure your place in history. The battle for survival awaits! */}
          The battlefield will be set, and AQUIS is your weapon. Each day brings a new chance to attack rival nations, strip them of their AQUIS, and strengthen your own position. Allocate your AQUIS wisely to unlock both offensive and defensive strategy. Level up, become legendary, and let the world of Zenthia know you're here to dominate.
          </div>
        </div>
      </div>
    </ThirdWrapper>
  );
};

Third.propTypes = {};

Third.defaultProps = {};

export default Third;
