import React, { FunctionComponent } from "react";
import styled from "styled-components";

const ActiveBoxWrapper = styled.div`
  width: 100%;
  .levels {
    display: flex;
  }
`;

const Shape = styled.div<any>`
  width: 100%;
  height: 40px;
  background-color: #4a4a86;
  position: relative;
  margin-right: 5px;
  clip-path: polygon(10% 0, 100% 0, 90% 100%, 0 100%);

  @media (max-width: 575px) {
    height: 20px;
  }
  &:last-child {
    margin-right: 0;
  }

  &:after {
    content: "";
    display: ${(props) => (props.on ? "block" : "none")};
    width: calc(100% - 6px);
    height: calc(100% - 6px);
    background: rgba(255, 255, 255, 0.4);
    position: absolute;
    top: 0;
    left: 0;
    clip-path: polygon(0 0, 0 98%, 53% 0);
  }

  &:before {
    content: "";
    clip-path: polygon(10% 0, 100% 0, 90% 100%, 0 100%);
    width: calc(100% - 6px);
    height: calc(100% - 6px);
    background: ${(props) => (props.on ? props.color : "#17173E")};
    display: block;
    position: absolute;
    top: 3px;
    left: 3px;
  }
`;

interface ActiveBoxProps {
  color?: any;
  limit?: any;
}

const ActiveBox: FunctionComponent<ActiveBoxProps> = ({ color, limit }) => {
  return (
    <ActiveBoxWrapper>
      <div className="levels">
        {Array(10)
          .fill(0)
          .map((item, index) => (
            <Shape
              key={index}
              className="shape mt7"
              on={index < limit}
              color={color}
            ></Shape>
          ))}
      </div>
    </ActiveBoxWrapper>
  );
};

ActiveBox.propTypes = {};

ActiveBox.defaultProps = {};

export default ActiveBox;
