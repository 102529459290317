import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IClanValue, IDemo, ITodo, IClanStakeValue, INFTValue } from "./../../interface/demo";

const initialState: IDemo = {
  loading: false,
  todos: [],
  leaders: [],
  clanValueName: null,
  nfts: [],
  clansStaked: []
};

const demoSlice = createSlice({
  name: "todos",
  initialState,
  reducers: {
    setLoading(state, action: PayloadAction<boolean>) {
      return {
        ...state,
        loading: action.payload,
      };
    },
    setTodos(state, action: PayloadAction<ITodo[]>) {
      return {
        ...state,
        todos: action.payload,
      };
    },
    setLeaders(state, action: PayloadAction<any>) {
      return {
        ...state,
        leaders: action.payload,
      };
    },
    setClanValue(state, action: PayloadAction<IClanValue>) {
      return {
        ...state,
        clanValueName: action.payload,
      };
    },
    setNFTValues(state, action: PayloadAction<INFTValue[]>) {
      return {
        ...state,
        nfts: action.payload,
      };
    },
    setClanStakeValues(state, action: PayloadAction<IClanStakeValue[]>) {
      return {
        ...state,
        clansStaked: action.payload,
      };
    },
  },
});

export const { setLoading, setTodos, setLeaders, setClanValue, setNFTValues, setClanStakeValues } = demoSlice.actions;

export default demoSlice.reducer;
