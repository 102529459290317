import React, { FunctionComponent } from "react";
import styled from "styled-components";
import {
  addressLinkLine,
  mobileAddressLinkLine,
  copyLinkBtn,
  mobileCopyLinkBtn,
  mobileTokenLine,
  tokenLine,
} from "helper/constants";

const AddressLinkWrapper = styled.div`
  .choose-clan {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
  }
  .name-token {
    margin-top: 0px !important;
  }
  .token-title {
    position: absolute;
    width: 606px;
    text-align: center;
    bottom: 32px;
  }
  .mobile_img {
    display: none !important;
  }
  .mobile-hide {
    display: none;
  }
  @media (max-width: 575px) {
    .token-title {
      max-width: 236px;
      font-size: 28px !important;
      bottom: 5px;
    }
    .web-part {
      display: none;
    }
    .mobile_img {
      display: block !important;
      margin-top: 45px;
    }
    .mobile-hide {
      display: block;
    }
    .mobile_detail {
      display: block;
      text-align: center;
      top: 26px !important;
    }
    .mobile_address {
      margin: 0 !important;
    }
    .mobile_title {
      font-size: 16px !important;
    }
  }
`;

interface AddressLinkProps {}

const AddressLink: FunctionComponent<AddressLinkProps> = () => {
  return (
    <AddressLinkWrapper>
    {/*  <div className="choose-clan name-token">
        <img
          src={mobileTokenLine}
          alt="mobileTokenLine"
          className="absolute mobile_img"
        />
        <img src={tokenLine} alt="tokenLine" className="absolute web-part" />
        <div className="an-48 b-bold-text token-title">Meet the name token</div>
        <img
          src={mobileAddressLinkLine}
          alt="mobileAddressLinkLine"
          className="absolute mobile_img"
          style={{ top: "15px", margin: 0 }}
        />
        <img
          src={addressLinkLine}
          alt="addressLinkLine"
          className="absolute web-part"
          style={{ top: "-20px" }}
        />
        <div
          className="flex justify-between absolute mobile_detail"
          style={{ top: "0" }}
        >
          <div className="mr70 mobile_address">
            <div className="an-24 vt-regular-text lightgray--text mobile_title">
              Adress:
            </div>
            <div className="an-20 b-bold-text my5 web-part">
              0xD199F0BAcfdb7d195a81906c39eA65f739
            </div>
            <div className="an-16 b-bold-text my5 mobile-hide">
              0xD199F0BAcfdb7d2162...
            </div>
          </div>
          <img
            src={mobileCopyLinkBtn}
            alt="mobileCopyLinkBtn"
            className="mobile-hide margin-auto"
          />
          <img src={copyLinkBtn} alt="copyLinkBtn" className="web-part" />
        </div>
      </div> */}
    </AddressLinkWrapper>
  );
};

AddressLink.propTypes = {};

AddressLink.defaultProps = {};

export default AddressLink;
