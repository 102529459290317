import { useNavigate } from 'react-router-dom';
import React, { FunctionComponent, useEffect, useState } from "react";
import styled from "styled-components";
import { Modal, Popover } from "antd";
import useDevice from "hooks/useDevice";
import { useSelector } from "react-redux";
import {
  spaceBackground,
  stakeNftBtn,
  nftCard1Img,
  nftCard2Img,
  nftCard3Img,
  nftQuestionCard,
  mobileStakeNftBtn,
  chooseClanBtn,
  moreIcon,
  disableIcon,
  chooseClanIcon
} from "helper/constants";
import StakeNftCard from "components/common/StakeNftCard";
import StakeNft from "components/gameScreen/StakeNft";
import Header from "components/gameScreen/Header";
import AllocateCoinCard from "components/gameScreen/AllocateCoinCard";
import { getNFTValues } from "redux/selectors/demo";
import { getClanValue } from "redux/selectors/demo";
import { INFTValue, IClanValue, CardItem  } from "interface/demo";

const GameScreenWrapper = styled.div`
  .game-screen {
    background: url(${spaceBackground});
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    height: 100%;
  }
  @media (max-width: 575px) {
    .mobile_title {
      margin: 38px 18px 0px 18px;
    }
    .mobile_stack_card {
      max-width: 575px;
      overflow: auto;
      margin: 0px 18px;
      padding-top: 35px;
    }
    .mb_size {
      width: 40px;
      height: 34px;
    }
  }
`;

interface GameScreenProps { }

const GameScreen: FunctionComponent<GameScreenProps> = () => {
  const [stakeNftModal, setStakeNftModal] = useState(false);
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [isUnstake, setIsUnstake] = useState(false);
  const [stakeAllowed, setStakeAllowed] = useState(0);
  const [coinUnstakedBalance, setCoinUnstakedBalance] = useState(0);
  const { isMobile } = useDevice();
  const nftValueList: INFTValue[] | null = useSelector(getNFTValues);
  const clanValue: IClanValue | null = useSelector(getClanValue);

  const [nftCards, setNftCards] = useState<CardItem[]>([]);
  const navigate = useNavigate();
  const showUnstakeNftModal = () => {
    // setStakeNftModal(true);
    // setIsUnstake(true);
    // setIsPopupVisible(false);
    navigate("/chooseClan");
  };

  const handleVisibleChange = (newVisible: boolean) => {
    setIsPopupVisible(newVisible);
  };

  const showStakeNftModal = () => {
    // setStakeNftModal(true);
    // setIsUnstake(false);
    navigate("/battleground");
  };

  const nftClose = () => {
    setStakeNftModal(false);
  };

  const content = (
    <div
      className="flex items-center cursor-pointer"
      onClick={showUnstakeNftModal}
    >
      <img src={chooseClanIcon} alt="chooseclan" />
      <div className="an-16 b-bold-text ml14 white--text m_head">
        CHANGE CLAN
      </div>
    </div>
  );



  useEffect(() => {
    const filteredData = nftValueList.filter(item => item.clan.toLowerCase() === clanValue?.title.toLowerCase());
    let nftCardsData: CardItem[] = [];
    let totalAllowedStake = 0;


    
    filteredData.forEach(item => {
      totalAllowedStake = totalAllowedStake + item.stake;
      // Do something with each item
      let url = "https://picsum.photos/id/" + item.id + "/200/200";

      let cardImage = nftCard1Img;

      if(item.clan == "Orcs"){
        cardImage = nftCard2Img;
      }else if(item.clan == "Elves"){
        cardImage = nftCard3Img;
      };

      const image_url = `/assets/${item.url}`;

      let card: CardItem = {
        cardIcon: image_url,
        nftId: item.id,
        price: item.stake,
        buyNewCard: false
      };
      nftCardsData.push(card);
    });

    setNftCards(nftCardsData);
    setStakeAllowed(totalAllowedStake);
    setCoinUnstakedBalance(15000);
  }, []);

  return (
    <GameScreenWrapper>
      <div className="game-screen">
        <div className="app-container pb35">
          <Header />
          <AllocateCoinCard stakeAllowed={stakeAllowed} unstakedCoinBalance={coinUnstakedBalance}/>

          <div className="flex justify-between items-center mt27 mobile_title">
            <div className={`${isMobile ? "an-20" : "an-28"} b-bold-text`}>
              Your NFT’s
            </div>
            <div>
              <img
                // src={isMobile ? mobileStakeNftBtn : stakeNftBtn}
                src={chooseClanBtn}
                alt="stakeNftBtn"
                className="cursor-pointer"
                onClick={showStakeNftModal}
                width={isMobile ? 138 : ""}
                height={isMobile ? 34 : ""}
              />
              <Popover
                placement="bottom"
                content={content}
                visible={isPopupVisible}
                onVisibleChange={handleVisibleChange}
                trigger="click"
                overlayClassName="more-icon-popup"
              >
                <img
                  src={moreIcon}
                  alt="moreIcon"
                  className={isMobile ? "ml10 mb_size" : "ml12 cursor-pointer"}
                />
              </Popover>
            </div>
            <Modal
              visible={stakeNftModal}
              footer={null}
              className="stake-nft-modal"
              width={"100%"}
            >
              <StakeNft nftClose={nftClose} isUnstake={isUnstake} nftCards={nftCards} unstakedCoinBalance={coinUnstakedBalance}/>
            </Modal>
          </div>

          <div className="flex pt10 mobile_stack_card flex_overflow">
              {nftCards.map((data, index) => (
                <div className={index > 0 && !isMobile ? "ml40" : ""} key={index}>
                  <StakeNftCard
                    cardIcon={data.cardIcon}
                    nftId={data.nftId}
                    Price={data.price}
                    index={index + 1}
                    isModal={false}
                    buyNewCard={data?.buyNewCard}
                  />
                </div>
              ))}
          <div>

            </div>
          </div>
        </div>
      </div>
    </GameScreenWrapper>
  );
};

GameScreen.propTypes = {};

GameScreen.defaultProps = {};

export default GameScreen;
