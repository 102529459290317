import React, { FunctionComponent } from "react";
import styled from "styled-components";

const TokenomicsCardWrapper = styled.div`
  background: #17173e;
  .card-description {
    max-width: 280px;
    line-height: 160%;
  }
  .img-card {
    width: 128px;
  }
  @media (max-width: 575px) {
    .card {
      padding: 22px 22px 22px 27px;
    }
    .card-description {
      font-size: 20px !important;
      padding-top: 16px !important;
    }
  }
`;

interface TokenomicsCardProps {
  title_img: string;
  title: string;
  description: string;
  customClass?: string;
}

const TokenomicsCard: FunctionComponent<TokenomicsCardProps> = ({
  title_img,
  title,
  description,
  customClass,
}) => {
  return (
    <TokenomicsCardWrapper id="tokenomics">
      <div className="py22 px30 card">
        <img className="img-card" src={title_img} alt="img" />
        <div className="an-24 b-bold-text mt26">{title}</div>
        <div
          className={`an-24 vt-regular-text lightgray--text pt20 card-description ${customClass}`}
        >
          {description}
        </div>
      </div>
    </TokenomicsCardWrapper>
  );
};

TokenomicsCard.propTypes = {};

TokenomicsCard.defaultProps = {};

export default TokenomicsCard;
