import ActiveBox from "components/common/ActiveBox";
import Footer from "components/common/Footer";
import TopNavigation from "components/common/TopNavigation";
import StartPlaying from "components/mainLayout/StartPlaying";
import {
  homeBackground, townhallLogo
} from "helper/constants";
import { FunctionComponent, useEffect, useState } from "react";
import styled from "styled-components";
import ClanBoardCard from "components/leaderboard/ClanBoardCard";

const LeaderboardWrapper = styled.div`  
  .main-page {
    background: url(${homeBackground});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: bottom;
    width: 100%;
    height: 900px;
  }

  .contentContainer{
    max-width: 1160px;
    margin: auto;
  }

  .warlordsLogo{
    position: relative; 
    top: 41px;
    width: 140px;
    height: 44.89px;
  }

  .leaderboardTitle{
    position: relative;
    top: 109px;
    font-size: 28px;
    font-family: "Borken-console-bold";
  }

  .cards {
    position: relative;
    top: 183px;
    display: flex;
    flex-wrap: wrap;
    max-width: 1160px;
    margin: auto;
  }

  .cards > * {
    width: 33.33%;
  }

  @media (max-width: 767px) {
    .warlordsLogo{
      top: 41px;
      margin: auto;
      display: block;
    }
  
    .leaderboardTitle{
      position: relative;
      top: 80px;
      font-size: 28px;
      text-align: center;
      font-family: "Borken-console-bold";
    }

    .cards {
      top: 100px;
    }

    .cards > * {
      width: 92%;
      margin: auto;
      padding-bottom: 20px;
    }
  };
`;

interface LeaderboardProps { }

const Leaderboard: FunctionComponent<LeaderboardProps> = () => {

  useEffect(() => {

  }, []);

  return (
    <LeaderboardWrapper>
      <div className="main-page">
        <div className="contentContainer">
          <img className="warlordsLogo" src={townhallLogo} alt="Warlords Logo"></img>
          <div className="leaderboardTitle">LEADERBOARD</div>
          <div className="cards">
            <ClanBoardCard rank={3} clan="elves" balance={203250} />
            <ClanBoardCard rank={1} clan="humans" balance={104350} />
            <ClanBoardCard rank={2} clan="orcs" balance={53290} />
          </div>
        </div>
      </div>
    </LeaderboardWrapper>
  );
};

Leaderboard.propTypes = {};

Leaderboard.defaultProps = {};

export default Leaderboard;
