import React, { FunctionComponent, useState } from "react";
import styled from "styled-components";
import { lessBtn, moreBtn } from "helper/constants";

const FaqWrapper = styled.div`
  .container {
    max-width: 875px;
  }
  @media (max-width: 575px) {
    .app-container {
      padding-inline: 20px;
      max-width: 575px;
    }
    .heading {
      font-size: 28px !important;
      font-weight: 700 !important;
    }
    .title {
      font-size: 16px !important;
      font-weight: 700 !important;
    }
    .container {
      max-width: 292px;
    }
    .faq-desc {
      padding-left: 0px !important;
    }
  }
`;

const data = [
  {
    id: 0,
    title: "What are warlords?",
    discriptions: [],
    discriptionLinks: [
      {
        title: "Warlords are a collection of battle commanders chosen to lead their nations to victory, and",
        link: "",
      },
      {
        title: "each warlord will allow you to allocate a set allowance of AQUIS token.",
        link: "",
      },
    ],
  },
  {
    id: 1,
    title: "When does the battle begin?",
    discriptions: [],

  discriptionLinks: [
    {
      title: "Warlords are hungry for battle! The battle will begin in 2025.",
      link: "",
    },

  ],
  },
  {
    id: 2,
    title: "How many warlords are ready to battle?",
    discriptions: [],

  discriptionLinks: [
    {
      title: "Warlords are a collection of 2000 battle commanders.",
      link: "",
    },

  ],
  },
  {
    id: 3,
    title: "How much does it cost to recuirt a warlord?",
    discriptions: [],
    discriptionLinks: [
      {
        title: "CL: TBD Public: TBD",
        link: "",
      },

  ],
  },
  {
    id: 4,
    title: "How can a would-be warlords get on the ClanList (CL)?",
    discriptions: [],
    discriptionLinks: [
      {
        title: "Details will be announced on X!",
        link: "",
      },

  ],
  },
  {
    id: 5,
    title: "What chain will host the battle?",
    discriptions: [],
    discriptionLinks: [
      {
        title: "TBD!",
        link: "",
      },

  ],
  },
];

interface FaqProps {}

const Faq: FunctionComponent<FaqProps> = () => {
  const [id, setId] = useState(null);

  const toggle = (newId: any) => {
    if (newId === id) {
      setId(null);
    } else {
      setId(newId);
    }
  };

  return (
    <FaqWrapper>
      <div className="app-container pb40" id="faq">
        <div className="an-58 b-bold-text pb30 pt30 heading ">FAQ </div>

        {data?.map((element: any, index) => {
          return (
            <div className="pb30" key={index}>
              <div className="flex items-center">
                <div className="self-start">
                  <img
                    alt="moreIcon"
                    className="cursor-pointer"
                    onClick={() => toggle(element.id)}
                    src={id === element.id ? lessBtn : moreBtn}
                  />
                </div>
                <div className="an-24 b-bold-text title pl21 container">
                  {element.title}
                </div>
              </div>
              <div className="pl60 faq-desc">
                {element.id === id && (
                  <div className="an-24 vt-regular-text lightgray--text">
                    <ul>
                      {element?.discriptions?.map((element: any) => {
                        return (
                          <>
                            <li> {element} </li>
                          </>
                        );
                      })}
                    </ul>
                    {element.discriptionLinks &&
                      element.discriptionLinks.length > 0 &&
                      element.discriptionLinks.map((data: any) => (
                        <div className="word-break">
                          <span>{data.title}</span>
                          <span className="pl10">{data.link}</span>
                        </div>
                      ))}
                  </div>
                )}
              </div>
            </div>
          );
        })}
      </div>
    </FaqWrapper>
  );
};

Faq.propTypes = {};

Faq.defaultProps = {};

export default Faq;
