import ActiveBox from "components/common/ActiveBox";
import Footer from "components/common/Footer";
import TopNavigation from "components/common/TopNavigation";
import StartPlaying from "components/mainLayout/StartPlaying";
import {
  battleField,
  battleFieldEmpty,
  elvesRankFlag,
  homeBackground,
  humansRankFlag,
  orcsRankFlag,
  rankOne,
  rankThree,
  rankTwo,
} from "helper/constants";
import { FunctionComponent, useEffect, useState, useMemo } from "react";
import styled from "styled-components";
import useDevice from "hooks/useDevice";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import AllocateCoinModal from "components/gameScreen/AllocateCoinModal";
import { Modal } from "antd";
import {
  researchIcon,
} from "helper/constants";
import StakeRequestModal from "components/gameScreen/StakeRequestModal";

const BattlegroundWrapper = styled.div`  
.main-page {
  background: url(${homeBackground});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom;
  width: 100%;
  height: 900px;
}

/* Styles for the flex container */
.flex-container {
  display: flex;
  flex-wrap: wrap; /* Allow items to wrap to the next line if needed */
  margin : auto;
  max-width: 1160px;
  padding-top: 13px;
}

.app-container{
  justify-content: center;
  align-items: start;
  // padding-top: 62px;
}

.owner-logo{
  padding-right: 20px;
  max-height 60px;
}

.welcome-title{
  font-size: 48px !important;
}

.clanRankElement{
  display: flex;
  width: 33.33%;
  flex-direction: column;
  max-height: 77.77px;
}

/* Styles for the top elements */
.top-element {
  display: flex;
  font-size: 20px;
  width: 100%; /* Each bottom element takes one-third of the container */
  text-align: left; /* Left-align the text */
}

/* Styles for the bottom elements */
.main-element {
  display: flex;
  width: 100%; /* Each bottom element takes one-third of the container */
}

/* Styles for the left div within each main element */
.left-div {
  width: 51px;
}

/* Styles for the image within each left div */
.left-div img {
  max-width: auto;
  height: 44.77px;
  display: block;
}

/* Styles for the right div within each main element */
.right-div {
  flex-grow: 1; /* Allow the right div to grow and take the remaining space */
  margin-left: 0px; /* Creates space between left and right divs */
}

/* Styles for the right div within each main element */
.right-div {
  display: flex;
  width: calc(100% - 69px); /* Subtract the width of the left div */
}

/* Styles for the left and right parts within each right div */
.left-part,
.right-part {
  flex-grow: 1;
}


/* Styles for the content within left and right parts */
.left-part p,
.right-part p {
  margin: 0; /* Remove default margin for the paragraphs */
  padding: 5px; /* Add padding as needed */
  font-size: 12px;
  font-family: "Borken-console-bold";
}

.left-part{
  max-width: 235px;
}
.right-part{
  max-width: 65px;
  text-align:right;
}

/* Additional styling for the right div within each main element */
.right-div {
  margin-left: 0px; /* Creates space between left and right divs */
}

.battle_field img {
  max-width: 1160px; /* Ensure the image takes the full width of its container */
  margin: auto;
  padding-top: 15px;
}

.shape{
  height:14.8px;
  max-width: 284px;
}

.mt7{
  margin-top: 0px;
  margin-bottom: 10px;
}

.rank-image {
  position: relative;
  widht: 21px !important;
  height: 21px !important;
  bottom: 33px;
  right: -10.5px;
  transform: translate(50%, 50%);
}

@media (max-width: 1160px) {
  .battle_field img {
    max-width: 100%; /* Ensure the image takes the full width of its container */
    margin: auto;
    padding-top: 15px;
    padding-bottom: 60px;
  }

  .top-element,
  .main-element {
    width: 90%; /* Make elements take the full width on mobile */
    margin-left: 5%;
  }

};

/* Media query for mobile devices */
@media (max-width: 767px) {
  /* Adjust styles for mobile view */
  .top-element,
  .main-element {
    width: 100%; /* Make elements take the full width on mobile */
    margin-left: auto;
  }

  .battle_field img {
    max-width: 100%; /* Ensure the image takes the full width of its container */
    margin: auto;
    padding-top: 15px;
    padding-bottom: 60px;
  }

  .welcome-title{
    font-size: 24px !important;
  }

  .mobile_btn{
    visibility: hidden;
    width: 0px;
  }

  .clanRankElement{
    display: flex;
    width: 90%;
    margin-left: 5%;
    flex-direction: column;
    max-height: 77.77px;
    padding-bottom: 120px;
  }

  .top-element p{
    text-align: center;
    width: 100%;
  }
}
`;

interface BattlegroundProps { }

const Battleground: FunctionComponent<BattlegroundProps> = () => {
  const [modalVisible, setModalVisible] = useState(false);
  const [confirmModalVisible, setConfirmModalVisible] = useState(false);
  const { isMobile } = useDevice();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [timeLeft, setTimeLeft] = useState({
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  // Set your target time here (e.g., 17 hours from now)
  // Memoize the target time to ensure it doesn't change on every render
  const targetTime = useMemo(() => {
    const now = new Date();
    // Set the hours to 23 (11 PM) and minutes, seconds, and milliseconds to 0 for UTC
    now.setUTCHours(23, 0, 0, 0);
    return now;
  }, []); // Empty dependency array ensures this runs only once  

  useEffect(() => {
    const interval = setInterval(() => {
      const now = new Date();
      const difference = targetTime.getTime() - now.getTime();

      if (difference > 0) {
        const hours = Math.floor((difference / (1000 * 60 * 60)) % 24);
        const minutes = Math.floor((difference / (1000 * 60)) % 60);
        const seconds = Math.floor((difference / 1000) % 60);

        setTimeLeft({ hours, minutes, seconds });
      } else {
        clearInterval(interval); // Stop countdown when time is up
      }
    }, 1000);

    return () => clearInterval(interval); // Cleanup on component 
  }, [targetTime]);

  const startPlayingClicked = function () {
    // setModalVisible(true);
  };

  const closeModalClicked = function (isConfirm: boolean) {
    setModalVisible(false);
    if (isConfirm) {
      setConfirmModalVisible(true);
    } else {
      setConfirmModalVisible(false);
    }
  };

  return (
    <BattlegroundWrapper>
      <div className="main-page">
        <TopNavigation isBattleground={true}></TopNavigation>
        {/* <Modal
          visible={modalVisible}
          footer={null}
          className="stake-nft-modal"
          width={"100%"}
        >
          <AllocateCoinModal allocateClose={closeModalClicked} />
        </Modal> */}
        <StakeRequestModal
          stakingVisible={confirmModalVisible}
          setStakingVisible={setConfirmModalVisible}
          requestIcon={researchIcon}
          requestTitle={'AQUIS Successfully Allocated.'}
          requestDesc="You have successfully allocated your AQUIS resources."
          showTransactionIcon={false}
        />
        <div className="flex-container" style={{marginTop: '30px'}}>
          <div style={{ margin: 'auto', textAlign: 'center' }}>
            <div className={`${isMobile ? "an-14" : "an-24"} b-regular-text white--text`}>
            Battle Starts in
            </div>
            <div className={`${isMobile ? "an-16" : "an-48"} b-bold-text battleyellow--text`}>
            {timeLeft.hours === 0 && timeLeft.minutes === 0 && timeLeft.seconds == 0 ? (
                "--h : --m : --s"
              ) : (
                `${timeLeft.hours.toString().padStart(2, "0")}h : ${timeLeft.minutes
                  .toString()
                  .padStart(2, "0")}m : ${timeLeft.seconds.toString().padStart(2, "0")}s`
              )}
            </div>
          </div>
          {/* <div className="clanRankElement">
            <div className="top-element ant-statistic-content-value">
              <p>ELVES</p>
            </div>
            <div className="main-element">
              <div className="left-div">
                <img src={elvesRankFlag} alt="Elves Flag" />
                <img src={rankOne} alt="rankImage" className="rank-image"/>
              </div>
              <div className="right-div ant-statistic-content-value">
                <div className="left-part">
                  <ActiveBox color={"lightgreen"} limit={5} />
                  <ActiveBox color={"lightgreen"} limit={5} />
                </div>
                <div className="right-part">
                  <p>{"4|10m"}</p>
                  <p>{"40%"}</p>
                </div>
              </div>
            </div>
          </div>
          <div className="clanRankElement">
            <div className="top-element ant-statistic-content-value">
              <p>Orcs</p>
            </div>
            <div className="main-element">
              <div className="left-div">
                <img src={orcsRankFlag} alt="Orcs Flag" />
                <img src={rankThree} alt="rankImage" className="rank-image"/>
              </div>
              <div className="right-div ant-statistic-content-value">
                <div className="left-part">
                  <ActiveBox color={"lightblue"} limit={5} />
                  <ActiveBox color={"lightblue"} limit={5} />
                </div>
                <div className="right-part">
                  <p>{"2|10m"}</p>
                  <p>{"20%"}</p>
                </div>
              </div>
            </div>
          </div>
          <div className="clanRankElement">
            <div className="top-element ant-statistic-content-value">
              <p>HUMANS</p>
            </div>
            <div className="main-element">
              <div className="left-div">
                <img src={humansRankFlag} alt="Humans Flag" />
                <img src={rankTwo} alt="rankImage" className="rank-image"/>
              </div>
              <div className="right-div ant-statistic-content-value">
                <div className="left-part">
                  <ActiveBox color={"red"} limit={5} />
                  <ActiveBox color={"red"} limit={5} />
                </div>
                <div className="right-part">
                  <p>{"4|10m"}</p>
                  <p>{"40%"}</p>
                </div>
              </div>
            </div>
          </div> */}
          <div className="battle_field">
            <img src={battleFieldEmpty} alt="Battlefield" />
          </div>
        </div>
        <StartPlaying showModal={startPlayingClicked} titleText="Fight on the field by choosing your clan" />
        <Footer hideLogo={true} />
      </div>
    </BattlegroundWrapper>
  );
};

Battleground.propTypes = {};

Battleground.defaultProps = {};

export default Battleground;
