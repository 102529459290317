import React, { FunctionComponent } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setNFTValues } from "redux/reducers/demo";
import {
  AST3,
  holderIcon,
  learnMoreBtn,
  liquidityIcon,
  moneyIcon,
  tokenomicsChart,
  mintNowHover,
} from "helper/constants";
import TokenomicsCard from "components/common/TokenomicsCard";

// Styled Components
const TokenomicsExplorationWrapper = styled.div`
  background: #393964 !important;
  padding: 20px 20px 75px;

  .title {
    line-height: 140%;
  }

  .bar-percentage{
    font-family: Borken-console-bold;
  }

  .bar-title{
    font-family: VT323-Regular;
  }

  .cards {
    display: grid;
    grid-template-columns: 32% 32% 32%;
    gap: 2%;

    @media (max-width: 1150px) {
      padding: 0 20px;
    }

    @media (max-width: 900px) {
      grid-template-columns: 100%;
    }
  }

  @media (max-width: 575px) {
    .title {
      padding: 47px 23px 26px;
      font-size: 36px !important;
      display: block;
    }
    .lm_btn {
      margin-top: 20px;
    }
  }

  @media (max-width: 768px) {
    .bar-title {
      transform: rotate(-90deg);
      transform-origin: left bottom;
      white-space: nowrap;
      position: absolute;
      bottom: -24px;
      left: 50%;
      font-size: 12px;
      text-align: center;
      font-weight: 400;
    }

    .bar-percentage {
      transform: rotate(-90deg);
      position: absolute;
      left: 50%;
      
      font-size: 10px;
      font-weight: 700;
      text-align: center;
    }

    .topbartext{
      position: absolute;
      bottom: 120px;
      left: 14px;
    }

    .bottombartext{
      position: absolute;
      left: 21px;
    }
  }

  @media (min-width: 769px) {
    .bar-title {
      text-align: center;
      font-size: 20px;
      font-weight: 400;
      margin-top: 5px;
    }

    .bar-percentage {
      text-align: center;
      font-size: 20px;
      font-weight: 700;
      color: #fff;
      margin-top: -25px;
    }

    .topbartext{
      position: absolute;
      bottom: 80px;
    }

    .bottombartext{
      position: absolute;
      top: 108px;
      // bottom: 108px;
    }
  }
`;

const AST3Wrapper = styled.div`
  @media (max-width: 575px) {
    display: none;
  }
`;

const chartData = [
  { percentage: 10, title: "Planet α Zenthia", background: "#008FD6" },
  { percentage: 20, title: "Planet β", background: "#33A6E0" },
  { percentage: 35, title: "Planet γ", background: "#66BDE9" },
  { percentage: 10, title: "LP", background: "#99D3F2" },
  { percentage: 10, title: "DAO", background: "#CCE9FB" },
  { percentage: 3, title: "Charity", background: "#E0F3FD" },
  { percentage: 6, title: "Contributers", background: "#F0F9FE" },
  { percentage: 6, title: "Founders", background: "#F7FCFF" },
];

interface TokenomicsExplorationProps {}

const TokenomicsExploration: FunctionComponent<TokenomicsExplorationProps> = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [hoverImg, setHoverImg] = React.useState(false);

  const handleOpen = async () => {
    // const dataArray = require("../../helper/NFTSampleData.json");

    // const nftValues = dataArray.map((item: any) => ({
    //   id: item.ID,
    //   clan: item.Clan,
    //   stake: item.Stake,
    //   url: item.Image,
    //   active: item.Active,
    // }));

    // await dispatch(setNFTValues(nftValues));
    // navigate("/chooseClan");
  };

  return (
    <div className="relative">
      <AST3Wrapper>
        <img
          src={AST3}
          alt="AST3"
          className="absolute"
          style={{ top: "200px" }}
        />
      </AST3Wrapper>
      <TokenomicsExplorationWrapper id="tokenomics">
        <div className="app-container">
          <div className="an-48 b-bold-text pt60 pb37 title flex justify-between">
            Tokenomics
          </div>
          <div
            className="an-24 vt-regular-text lightgray--text pt25 townHall-description relative z-index1"
            style={{ paddingBottom: "50px" }}
          >
          An unexpectedly rare solistice has just occured, the suns have unfortunately aligned to create a once in a millenia drought, Aquis ($AQI) that once rained heavy upon the land is now far and few! 
          The three great factions that once flourished in peace together are now forced to fight to survive by capturing as much AQUIS as possible! It is expected that this planet will run out of AQUIS in under 3 to 6 weeks (maybe 3 to 6 years of in-game time). 
          Your expertise as a great leader of your faction is now required more than ever to lead it to victory and as supplies dwindle you must figure out a way to move your nation onto another planet! A game of decisive strategy, no luck or randomness, balanced gameplay with high stakes, and even higher rewards!
          </div>

          {/* Horizontal Chart for Desktop */}
          <div className="flex items-center py100" style={{ maxWidth: "90%", paddingLeft: "5%" }}>
            {chartData.map((a: any, index) => (
              <div
                key={a.title}
                style={{ width: `${a.percentage}%` }}
                className="flex_row relative"
              >
                {index % 2 === 0 ? (
                  <div className='topbartext'>
                    <div className="bar-percentage">{a.percentage}%</div>
                    <div className="bar-title">{a.title}</div>
                  </div>
                ) : (
                  ""
                )}
                <div className="my20" style={{ background: `${a.background}`, height: "50px" }}></div>
                {index % 2 !== 0 ? (
                  <div className='bottombartext'>
                    <div className="bar-percentage">{a.percentage}%</div>
                    <div className="bar-title">{a.title}</div>
                  </div>
                ) : (
                  ""
                )}
              </div>
            ))}
          </div>

          <div className="an-48 b-bold-text pt60 pb37 title flex justify-between" id="gamestep">
            GAME STEPS
            <img
              onClick={handleOpen}
              onMouseOver={() => setHoverImg(true)}
              onMouseLeave={() => setHoverImg(false)}
              src={"/assets/demo_soon.png"}
              alt="startDemoBtn"
              className="lm_btn"
            />
          </div>

          <div className="flex cards">
            <TokenomicsCard
              title_img={"/assets/flags.png"}
              title="Choose Your Clan"
              description="Get in on the ground floor of Zenthia's mad dash for survival and pick your side - Human, Orc, or Elf - and lead your clan into battle."
            />
            <TokenomicsCard
              title_img={"/assets/attack.png"}
              title="Attack Other Clans"
              description="Every 24 hours, it's time to bring the thunder. Attack their lands, steal their AQUIS, and make them rue the day they crossed paths with you."
            />
            <TokenomicsCard
              title_img={"/assets/defend.png"}
              title="Defend Your Clan"
              description="It's not all about going on the offensive; you've got to keep your home front secure. Spend your AQUIS wisely to strengthen your defenses."
            />
          </div>
        </div>
      </TokenomicsExplorationWrapper>
    </div>
  );
};

TokenomicsExploration.propTypes = {};
TokenomicsExploration.defaultProps = {};

export default TokenomicsExploration;
